import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import LinkMatch from "../LinkMatch";
import * as Styled from "./styles";

const Sidebar: FC = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  return (
    <Styled.Sidebar isOpen={isOpen}>
      <Styled.Item>
        <LinkMatch
          onClick={() => setIsOpen(!isOpen)}
          to="#"
          children={<Styled.Menu isOpen={isOpen} />}
        />
      </Styled.Item>
      <Styled.List isOpen={isOpen}>
        <Styled.Item>
          <LinkMatch to="relatorios" children={<Styled.Logo />} />
        </Styled.Item>
        <Styled.Item>
          <LinkMatch
            to="#"
            onClick={(e) => {
              e.preventDefault();
              window.open(
                `https://wa.me/5564992491664?text=Preciso de ajuda`,
                "_BLANK"
              );
            }}
            children={<Styled.WhatsApp />}
          />
        </Styled.Item>
        <Styled.Item>
          <LinkMatch
            onClick={(e) => {
              e.preventDefault();
              window.open(`mailto:${process.env.REACT_APP_EMAIL}`, "_BLANK");
            }}
            to="#"
            children={<Styled.Email />}
          />
        </Styled.Item>
      </Styled.List>
    </Styled.Sidebar>
  );
};

export default Sidebar;
