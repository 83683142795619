import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Loading } from "../../Components/UI";
import { PeriodoDate } from "../../Components/UI/Inputs";
import { StatusRelatorio } from "../../Data/Interfaces/Response/Relatorios/StatusRelatorio";
import { RootState } from "../../Redux";
import { PeriodoActions } from "../../Redux/Relatorios/Periodo/Action";
import { useQuery } from "../../Utils/Url";
import {
  Actions,
  Box,
  Container,
  Content,
  Item,
  Items,
  Quantidade,
  Status,
  Title,
  TitlePage,
  BackgroundTitle,
} from "./styles";

interface IDashBoard {}

const Dashboard: FC<IDashBoard> = ({}) => {
  const [periodo, setPeriodo] = useState<number>(0);
  const query = useQuery();

  const {
    configuracoes: {
      result: { data },
      isLoading,
    },
  } = useSelector((states: RootState) => {
    return {
      configuracoes: states.relatorios.configuracoes,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(PeriodoActions.fetchPeriodoSucceeded(periodo));
  }, [periodo]);

  return (
    <Loading isLoading={isLoading}>
      <Container>
        <Content>
          <BackgroundTitle>
            <TitlePage>VALIDAÇÃO DE DOCUMENTOS</TitlePage>
          </BackgroundTitle>
          <Actions>
            <PeriodoDate
              onSelectItem={(val) => {
                setPeriodo(+val);
              }}
              name="Periodo"
              options={[
                {
                  name: "Últimas 24 horas",
                  value: 0,
                },
                {
                  name: "Últimos 7 dias",
                  value: 7,
                },
                {
                  name: "Últimos 30 dias",
                  value: 30,
                },
                {
                  name: "Últimos 90 dias",
                  value: 90,
                },
              ]}
              value={periodo}
            />
            {/* <PrimaryButton
            icon={<FileCopy fontSize="small" />}
            customColor={"primary"}
            onClick={() => {
              navigate("novo");
            }}
          >
            Novo Relatorio
          </PrimaryButton> */}
          </Actions>
          {data?.map((x) => {
            return (
              <Box key={`box-${x.configuracaoRelatorio.identificador}`}>
                <Title>
                  <span>{x.configuracaoRelatorio.nome}</span>
                </Title>
                <Items>
                  <Item>
                    <Link
                      className={x.qtdProcessando <= 0 ? "disabled-link" : ""}
                      to={`detail/${x.configuracaoRelatorio.identificador}/${
                        StatusRelatorio.PROCESSANDO
                      }/${periodo}?pesquisa=${query.get("pesquisa")}`}
                    >
                      <Quantidade color="info">{x.qtdProcessando}</Quantidade>
                      <Status>Processando</Status>
                    </Link>
                  </Item>
                  <Item>
                    <Link
                      className={x.qtdRevisar <= 0 ? "disabled-link" : ""}
                      to={`detail/${x.configuracaoRelatorio.identificador}/${
                        StatusRelatorio.REVISAR
                      }/${periodo}?pesquisa=${query.get("pesquisa")}`}
                    >
                      <Quantidade color="waiting">{x.qtdRevisar}</Quantidade>
                      <Status>Devem ser revisados</Status>
                    </Link>
                  </Item>
                  <Item>
                    <Link
                      className={x.qtdAprovados <= 0 ? "disabled-link" : ""}
                      to={`detail/${x.configuracaoRelatorio.identificador}/${
                        StatusRelatorio.CONCLUIDO
                      }/${periodo}?pesquisa=${query.get("pesquisa")}`}
                    >
                      <Quantidade color="success">{x.qtdAprovados}</Quantidade>
                      <Status>Concluidos</Status>
                    </Link>
                  </Item>
                  <Item>
                    <Link
                      className={x.qtdReprovado <= 0 ? "disabled-link" : ""}
                      to={`detail/${x.configuracaoRelatorio.identificador}/${
                        StatusRelatorio.RECUSADO
                      }/${periodo}?pesquisa=${query.get("pesquisa")}`}
                    >
                      <Quantidade color="error">{x.qtdReprovado}</Quantidade>
                      <Status>Reprovados</Status>
                    </Link>
                  </Item>
                </Items>
              </Box>
            );
          })}
        </Content>
      </Container>
    </Loading>
  );
};

export default Dashboard;
