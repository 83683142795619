import { combineReducers } from "redux";
import all from "./All/reducer";
import configuracoes from "./Configuracoes/reducer";
import novo from "./Novo/reducer";
import dados from "./Detalhes/reducer";
import PeriodoReducer from "./Periodo/Reducer";
import respostaManualConsulta from "./RespostaManualConsulta/reducer";
import aprovaManualRelatorio from "./AprovaManualRelatorio/reducer";
import processarRelatorio from "./ProcessarRelatorio/reducer";
import atualizarStatus from "./AtualizarStatus/reducer";

export default combineReducers({
  all,
  configuracoes,
  novo,
  dados,
  periodo: PeriodoReducer,
  respostaManualConsulta,
  aprovaManualRelatorio,
  processarRelatorio,
  atualizarStatus
});
