export type ColorName =
  | "default"
  | "transparent"
  | "secondary"
  | "bgHeader"
  | "primary"
  | "success"
  | "waiting"
  | "info"
  | "error"
  | "bgGrayBlack"
  | "titleGray"
  | "titleWhite"
  | "bgDefault";

export const colorClasses = {
  default: "#004A99",
  primary: "#004A99",
  secondary: "66CCFF",
  transparent: "transparent",
  bgHeader: "#dedede",
  success: "#3ee610",
  waiting: "#e6a210",
  info: "#1069e6",
  error: "#f50505",
  bgGrayBlack: "#a3a3a3",
  titleGray: "#404040",
  titleWhite: "#FFF",
  bgDefault: "#f5f5ff",
};
