import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";
import { IResponseAPI } from "../../../Data/Interfaces/Response/Base/IResponseAPI";
import { IFotosDadosRelatorio } from "../../../Data/Interfaces/Response/Relatorios/IDadosRelatorio";
import RelatorioAPI from "../../../Data/Services/Relatorios";
import {
  fetchDetalhesRelatorioAction,
  DetalhesRelatorioActions,
} from "./action";

export function* fetchDetalhesRelatorio(action: fetchDetalhesRelatorioAction) {
  try {
    const { data } = yield call(
      RelatorioAPI.fetchDetalhesRelatorio,
      action.payload
    );

    const fotosData: AxiosResponse<IResponseAPI<IFotosDadosRelatorio>> =
      yield call(RelatorioAPI.fetchFotosDetalhesRelatorio, action.payload);

    yield put(
      DetalhesRelatorioActions.fetchDetalhesRelatorioSucceeded(
        data,
        fotosData.data
      )
    );
  } catch (e: any) {
    yield put(DetalhesRelatorioActions.fetchDetalhesRelatorioFailed(e.message));
  }
}
