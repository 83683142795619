import { FC, ReactNode } from "react";
import { Link, LinkProps, useMatch, useResolvedPath } from "react-router-dom";

interface Props extends LinkProps {
  to: string;
  children: ReactNode;
}

const LinkMatch: FC<Props> = ({ to, children, ...props }): JSX.Element => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (
    <span className={`${match ? "active" : ""}`}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </span>
  );
};
export default LinkMatch;
