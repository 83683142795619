import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";
import { IRespostaManualConsulta } from "../../../Data/Interfaces/Request/Relatorio/IRespostaManualConsulta";

export enum RespostaManualConsultaActionKeys {
  FETCH_RESPOSTA_MANUAL_CONSULTA_STARTED = "[RELATORIOS] FETCH_RESPOSTA_MANUAL_CONSULTA_STARTED",
  FETCH_RESPOSTA_MANUAL_CONSULTA_SUCCEEDED = "[RELATORIOS] FETCH_RESPOSTA_MANUAL_CONSULTA_SUCCEEDED",
  FETCH_RESPOSTA_MANUAL_CONSULTA_FAILED = "[RELATORIOS] FETCH_RESPOSTA_MANUAL_CONSULTA_FAILED",
}

export const RespostaManualConsultaActions = {
  fetchRespostaManualConsulta: (
    data: IRespostaManualConsulta
  ): fetchRespostaManualConsultaAction =>
    createAction(
      RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_STARTED,
      data
    ),
  fetchRespostaManualConsultaSucceeded:
    (): fetchRespostaManualConsultaSuccededAction =>
      createAction(
        RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_SUCCEEDED
      ),
  fetchRespostaManualConsultaFailed: (
    error: IResponseErrorAPI
  ): fetchRespostaManualConsultaFailedAction =>
    createAction(
      RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_FAILED,
      error
    ),
};

export type RespostaManualConsultaActionUnion = ActionsUnion<
  typeof RespostaManualConsultaActions
>;

export type fetchRespostaManualConsultaAction = Action<
  RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_STARTED,
  IRespostaManualConsulta
>;

export type fetchRespostaManualConsultaSuccededAction =
  Action<RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_SUCCEEDED>;

export type fetchRespostaManualConsultaFailedAction = Action<
  RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_FAILED,
  IResponseErrorAPI
>;
