import { FloatInputFormActionKeys, FloatInputFormActionUnion } from "./action";
import { IFloatInputFormState } from "./IFloatInputFormState";

const initialState: IFloatInputFormState = {
  isOpen: false,
  data: {
    inputValue: undefined,
  },
};

const floatInputReducer = (
  state = initialState,
  action: FloatInputFormActionUnion
): IFloatInputFormState => {
  switch (action.type) {
    case FloatInputFormActionKeys.FETCH_FLOAT_FORM_INPUT_OPEN:
      return {
        ...state,
        isOpen: true,
        data: action.payload,
      };
    case FloatInputFormActionKeys.FETCH_FLOAT_FORM_INPUT_CLOSE:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

export default floatInputReducer;
