import { FormControl, MenuItem, Select } from "@material-ui/core";
import styled from "@emotion/styled";
import { colorClasses } from "../../../../Utils/Color";

export const CustomSelect = styled(Select)`
  color: #000;
  font-weight: 700;

  fieldset {
    border: none;
  }
`;

export const CustomFormControl = styled(FormControl)`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg.MuiSvgIcon-root {
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${colorClasses["bgGrayBlack"]};
    height: 100%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }

  max-height: 2.5rem;

  background-color: ${colorClasses["bgHeader"]};
  border-radius: 0.5rem;

  svg.MuiSelect-icon {
    display: none;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  width: 100%;
`;
