import {
  AprovaManualRelatorioActionKeys,
  AprovaManualRelatorioActionUnion,
} from "./action";
import { IAprovaManualRelatorioState } from "./IAprovaManualRelatorioState";

const initialState: IAprovaManualRelatorioState = {
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: AprovaManualRelatorioActionUnion
): IAprovaManualRelatorioState => {
  switch (action.type) {
    case AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
