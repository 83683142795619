import { call, put, select } from "redux-saga/effects";
import { RootState } from "../..";
import { DetalhesRelatorioActions } from "../Detalhes/action";
import { IDetalhesRelatorioState } from "../Detalhes/IDetalhesRelatorioState";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import {
  AprovaManualRelatorioActions,
  fetchEditDadosRelatorioValueAction,
} from "./action";
import { IDadosRelatorio } from "./../../../Data/Interfaces/Response/Relatorios/IDadosRelatorio";

export function* fetchAprovaManualRelatorio() {
  try {
    const stateDadosRelatorio = (root: RootState) => root.relatorios.dados;
    const {
      result: { data },
    }: IDetalhesRelatorioState = yield select(stateDadosRelatorio);

    const newData: IDadosRelatorio = {
      ...data,
      fotoDocumento: null,
      fotoPerfil: null,
    };

    yield call(RelatoriosAPI.fetchAprovaManualRelatorio, newData);

    yield put(
      AprovaManualRelatorioActions.fetchAprovaManualRelatorioSucceeded()
    );
    yield put(DetalhesRelatorioActions.fetchDetalhesRelatorio(data.id));
  } catch (e: any) {
    yield put(
      AprovaManualRelatorioActions.fetchAprovaManualRelatorioFailed(e.message)
    );
  }
}

export function* fetchEditDadosRelatorio(
  action: fetchEditDadosRelatorioValueAction
) {
  try {
    const stateDadosRelatorio = (root: RootState) => root.relatorios.dados;
    const {
      result: { data },
    }: IDetalhesRelatorioState = yield select(stateDadosRelatorio);

    const consultaIdx = data.consultas.findIndex(
      (x) => x.id === action.payload.idConsulta
    );

    const consultaView = data.consultas[consultaIdx][action.payload.typeView];
    const consultaViewData = consultaView[consultaView.length - 1];
    consultaViewData[action.payload.propertyName] = action.payload.newValue;
    yield put(DetalhesRelatorioActions.fetchDetalhesRelatorioCustom({ ...data }));
  } catch (e: any) { }
}
