import { call, put, select } from "redux-saga/effects";
import { RootState } from "../..";
import { DetalhesRelatorioActions } from "../Detalhes/action";
import { IDetalhesRelatorioState } from "../Detalhes/IDetalhesRelatorioState";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import {
  ProcessarRelatorioActions,
} from "./action";

export function* fetchProcessarRelatorio(
) {
  try {
    const stateDadosRelatorio = (root: RootState) => root.relatorios.dados;
    const {
      result: { data },
    }: IDetalhesRelatorioState = yield select(stateDadosRelatorio);

    yield call(RelatoriosAPI.fetchProcessaRelatorio, data.id);

    yield put(
      ProcessarRelatorioActions.fetchProcessarRelatorioSucceeded()
    );
    yield put(DetalhesRelatorioActions.fetchDetalhesRelatorio(data.id));
  } catch (e: any) {
    yield put(
      ProcessarRelatorioActions.fetchProcessarRelatorioFailed(e.message)
    );
  }
}
