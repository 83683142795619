import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { StatusRelatorio } from "../../../Data/Interfaces/Response/Relatorios/StatusRelatorio";
import { RootState } from "../../../Redux";
import { DetalhesRelatorioActions } from "../../../Redux/Relatorios/Detalhes/action";
import { ColorName } from "../../../Utils/Color";
import DetailContainer from "../../../Components/UI/DetailContainer";
import * as Styled from "./styles";
import { Loading } from "../../../Components/UI";
import { RespostaManualConsultaActions } from "../../../Redux/Relatorios/RespostaManualConsulta/action";
import { Button, Menu, MenuItem, MenuProps } from "@material-ui/core";
import styled from "styled-components";
import { ArrowDropDown } from "@material-ui/icons";
import { ProcessarRelatorioActions } from "../../../Redux/Relatorios/ProcessarRelatorio/action";
import { AtualizarStatusRelatorioActions } from "../../../Redux/Relatorios/AtualizarStatus/action";

export type typeViewRelatorioProps =
  | "consultaAntt"
  | "faceMatch"
  | "ocR_CRLV"
  | "ocR_CNH"
  | "ocR_CanhotoDigital";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
  },
}));

const DetalhesRelatorio: FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const params = useParams();
  const dispatch = useDispatch();
  const [typeView, setTypeView] = useState<typeViewRelatorioProps | undefined>(
    undefined
  );

  const { dadosRelatorio, isLoading } = useSelector((states: RootState) => {
    return {
      dadosRelatorio: states.relatorios.dados,
      isLoading:
        states.relatorios.dados.isLoading ||
        states.relatorios.respostaManualConsulta.isLoading ||
        states.relatorios.aprovaManualRelatorio.isLoading ||
        states.relatorios.atualizarStatus.isLoading ||
        states.relatorios.processarRelatorio.isLoading,
    };
  });

  useEffect(() => {
    dispatch(
      DetalhesRelatorioActions.fetchDetalhesRelatorio(params.idRelatorio || "")
    );
  }, [params.idRelatorio, dispatch]);

  const syntaxHighlight = (json: object | string) => {
    if (typeof json != "string") {
      json = JSON.stringify(json, undefined, 2);
    }
    json = json
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        return match;
      }
    );
  };

  const getStatusColor = (status: StatusRelatorio): ColorName => {
    switch (status) {
      case StatusRelatorio.CONCLUIDO:
        return "success";
      case StatusRelatorio.PROCESSANDO:
        return "info";
      case StatusRelatorio.REVISAR:
        return "waiting";
      case StatusRelatorio.RECUSADO:
        return "error";
      default:
        return "default";
    }
  };

  const dataToView = dadosRelatorio.result.data.consultas?.find(
    (x) => typeView && x[typeView] && x[typeView].length > 0
  );

  const handleTypeExtensionFile = (
    file: string | null,
    extension: string
  ): JSX.Element => {
    return (
      <>
        {extension === "pdf" && (
          <Styled.MainIFrame>
            <Styled.IFrame
              title="123"
              src={`data:application/pdf;base64,${file}`}
            />
          </Styled.MainIFrame>
        )}
        {extension !== "pdf" && extension !== "docx" && (
          <Styled.Image
            title="Foto perfil"
            src={`data:image/*;base64,${file}`}
          />
        )}
      </>
    );
  };

  return (
    <Loading isLoading={isLoading}>
      <Styled.Container>
        <Styled.Header>
          <Styled.Title
            color={getStatusColor(dadosRelatorio?.result?.data.status)}
          >
            {dadosRelatorio?.result.data.nomeDocumento ||
              "Documento não detectado"}
          </Styled.Title>
          <Button
            id="demo-customized-button"
            aria-controls={openMenu ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? "true" : undefined}
            variant="contained"
            style={{
              marginLeft: 10,
            }}
            disableElevation
            onClick={handleClick}
            endIcon={<ArrowDropDown />}
          >
            Ações
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
          >
            {dadosRelatorio.result.data.status ===
              StatusRelatorio.PROCESSANDO && (
              <MenuItem
                onClick={() => {
                  dispatch(ProcessarRelatorioActions.fetchProcessarRelatorio());
                  handleClose();
                }}
              >
                Processar Novamente
              </MenuItem>
            )}
            <MenuItem
              onClick={() => {
                dispatch(
                  AtualizarStatusRelatorioActions.fetchAtualizarStatusRelatorio()
                );
                handleClose();
              }}
            >
              Atualizar Status
            </MenuItem>
            {dadosRelatorio.result.data.status === StatusRelatorio.REVISAR && (
              <MenuItem
                onClick={() => {
                  for (
                    let index = 0;
                    index < dadosRelatorio.result.data.consultas.length;
                    index++
                  ) {
                    const element = dadosRelatorio.result.data.consultas[index];
                    dispatch(
                      RespostaManualConsultaActions.fetchRespostaManualConsulta(
                        {
                          idConsulta: element.id,
                          idRelatorio: dadosRelatorio.result.data.id,
                        }
                      )
                    );
                  }
                }}
              >
                Reprovar Relatório
              </MenuItem>
            )}
          </StyledMenu>
        </Styled.Header>

        <Styled.Wrapper>
          <Styled.Box>
            <Styled.List>
              <Styled.Item key={1} onClick={() => setTypeView(undefined)}>
                Documentos
              </Styled.Item>
              {dadosRelatorio.result.data.consultas?.find(
                (x) => x["ocR_CNH"] && x["ocR_CNH"].length > 0
              ) && (
                <Styled.Item key={2} onClick={() => setTypeView("ocR_CNH")}>
                  Dados do documento
                </Styled.Item>
              )}
              {dadosRelatorio.result.data.consultas?.find(
                (x) =>
                  x["ocR_CanhotoDigital"] && x["ocR_CanhotoDigital"].length > 0
              ) && (
                <Styled.Item
                  key={2}
                  onClick={() => setTypeView("ocR_CanhotoDigital")}
                >
                  Dados do Canhoto
                </Styled.Item>
              )}
              {dadosRelatorio.result.data.consultas?.find(
                (x) => x["ocR_CRLV"] && x["ocR_CRLV"].length > 0
              ) && (
                <Styled.Item key={3} onClick={() => setTypeView("ocR_CRLV")}>
                  Dados do documento
                </Styled.Item>
              )}
              {dadosRelatorio.result.data.consultas?.find(
                (x) => x["faceMatch"] && x["faceMatch"].length > 0
              ) && (
                <Styled.Item key={4} onClick={() => setTypeView("faceMatch")}>
                  Comparação Facial
                </Styled.Item>
              )}
              {dadosRelatorio.result.data.consultas?.find(
                (x) => x["consultaAntt"] && x["consultaAntt"].length > 0
              ) && (
                <Styled.Item
                  key={5}
                  onClick={() => setTypeView("consultaAntt")}
                >
                  Dados de OCR
                </Styled.Item>
              )}
              {dadosRelatorio.result.data.consultas?.find(
                (x) => x["consultaAntt"] && x["consultaAntt"].length > 0
              ) && (
                <Styled.Item
                  key={6}
                  onClick={() => setTypeView("consultaAntt")}
                >
                  onClick={() => setTypeView("consultaAntt")}
                  Busca RNTRC
                </Styled.Item>
              )}
            </Styled.List>
          </Styled.Box>
          <Styled.Box>
            {typeView === undefined && (
              <Styled.BoxImages>
                {Boolean(dadosRelatorio.result.data.fotoPerfil) && (
                  <Styled.ConfigImage>
                    <Styled.TittleImage>Foto Perfil</Styled.TittleImage>
                    {handleTypeExtensionFile(
                      dadosRelatorio.result.data.fotoPerfil,
                      dadosRelatorio.result.data.nameFotoPerfil !== null
                        ? dadosRelatorio.result.data.nameFotoPerfil.split(
                            "."
                          )[1]
                        : ""
                    )}
                  </Styled.ConfigImage>
                )}
                {Boolean(dadosRelatorio.result.data.fotoDocumento) && (
                  <Styled.ConfigImage>
                    <Styled.TittleImage>Foto Documento</Styled.TittleImage>
                    {handleTypeExtensionFile(
                      dadosRelatorio.result.data.fotoDocumento,
                      dadosRelatorio.result.data.nameFotoDocumento !== null
                        ? dadosRelatorio.result.data.nameFotoDocumento.split(
                            "."
                          )[1]
                        : ""
                    )}
                  </Styled.ConfigImage>
                )}
              </Styled.BoxImages>
            )}
            {typeView !== undefined &&
              dataToView &&
              dadosRelatorio.result.data.consultas?.find(
                (x) => x[typeView] && x[typeView].length > 0
              ) && (
                <Styled.DataDocuments>
                  <DetailContainer
                    consulta={dataToView}
                    typeView={typeView}
                    reprovarDocumento={(idConsulta) => {
                      dispatch(
                        RespostaManualConsultaActions.fetchRespostaManualConsulta(
                          {
                            idConsulta,
                            idRelatorio: dadosRelatorio.result.data.id,
                          }
                        )
                      );
                    }}
                    statusRelatorio={dadosRelatorio.result.data.status}
                    data={dataToView[typeView]}
                  />
                </Styled.DataDocuments>
              )}
          </Styled.Box>
        </Styled.Wrapper>
      </Styled.Container>
    </Loading>
  );
};

export default DetalhesRelatorio;
