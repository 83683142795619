import { SelectChangeEvent, SelectProps } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import React, { FC } from "react";
import { CustomFormControl, CustomMenuItem, CustomSelect } from "./styles";

interface IPeriodoDate extends SelectProps {
  name: string;
  options: { name: string; value: string | number }[];
  onSelectItem?: (val: string | number | any) => void;
}

const PeriodoDate: FC<IPeriodoDate> = ({ options, ...props }) => {
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    props.onSelectItem?.(value);
  };

  return (
    <>
      <CustomFormControl
        onClick={() => setOpen(!open)}
        sx={{ m: 1, width: 300 }}
      >
        <CalendarToday fontSize="small" />
        <CustomSelect
          {...props}
          onChange={handleChange}
          value={props.value}
          open={open}
          onOpen={() => setOpen(!open)}
        >
          {options.map((opt) => (
            <CustomMenuItem
              key={opt.name}
              selected={opt.value === props.value}
              value={opt.value}
            >
              {opt.name}
            </CustomMenuItem>
          ))}
        </CustomSelect>
      </CustomFormControl>
    </>
  );
};

export default PeriodoDate;
