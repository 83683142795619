import styled from "styled-components";
import { colorClasses, ColorName } from "../../Utils/Color";

import BackgroundTitleImage from "../../Static/Images/title_bg.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem;
  padding-top: 2rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const TitlePage = styled.h2`
  font-weight: 700;
  color: ${colorClasses["titleWhite"]};
  font-family: "Ford Antenna Medium Italic";
  letter-spacing: 5px;
  font-size: 35px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: -1rem;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colorClasses["bgHeader"]};
  min-height: 10rem;
  border-radius: 0.4rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid ${colorClasses["bgHeader"]};
  width: 100%;
  &:not(:first-child) {
    margin-top: 2rem;
  }
`;

export const Title = styled.p`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${colorClasses["bgHeader"]};

  span {
    padding-left: 1rem;
    color: ${colorClasses["titleGray"]};
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  align-content: center;
`;

export const Item = styled.div`
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    align-content: center;
    align-items: center;
    color: inherit;
  }

  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;

  &:not(:last-child) {
    border-right: 1px solid ${colorClasses["bgHeader"]};
  }
`;

export const Quantidade = styled.span<{ color: ColorName }>`
  font-size: 2rem;
  font-weight: bold;
  color: ${(props) => colorClasses[props.color]};
`;

export const Status = styled.span`
  text-transform: uppercase;
  font-weight: 500;
`;

export const BackgroundTitle = styled.header`
  width: 100%;
  height: 150px;
  background-image: url(${BackgroundTitleImage});
  background-size: 600px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
`;
