import { FC, SVGProps } from "react";

const WhatsAppIcon: FC = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 35.324 34.92"
      {...props}
    >
      <g
        id="Grupo_86"
        data-name="Grupo 86"
        transform="translate(-203.756 -155.858)"
      >
        <path
          id="Caminho_43"
          data-name="Caminho 43"
          d="M203.756,190.778c.318-1.154.6-2.189.887-3.224.447-1.606.883-3.216,1.352-4.816a1.948,1.948,0,0,0-.16-1.574,17.1,17.1,0,0,1,2.309-19.285,17.786,17.786,0,0,1,25.6-1.121,17.173,17.173,0,0,1-.87,25.658,17.644,17.644,0,0,1-19.106,2.391,2.275,2.275,0,0,0-1.681-.116c-2.627.691-5.261,1.357-7.892,2.03C204.107,190.743,204.016,190.745,203.756,190.778Zm4.2-4.034c1.694-.429,3.164-.78,4.62-1.182a1.988,1.988,0,0,1,1.661.207,13.987,13.987,0,0,0,7.173,1.9,14.54,14.54,0,0,0,10.907-4.716,14.644,14.644,0,1,0-23.295-2.146,1.406,1.406,0,0,1,.182,1.3c-.262.823-.474,1.663-.7,2.5C208.331,185.252,208.171,185.9,207.954,186.744Z"
          fill="#f5f5ff"
        />
        <path
          id="Caminho_44"
          data-name="Caminho 44"
          d="M226.058,181.635c-.6-.118-1.215-.2-1.8-.361a17.247,17.247,0,0,1-9.152-6.421,13.573,13.573,0,0,1-2.138-3.448,5.016,5.016,0,0,1,.939-5.513,2.417,2.417,0,0,1,2.466-.634,1.126,1.126,0,0,1,.637.6c.491,1.064.926,2.153,1.385,3.232a1.189,1.189,0,0,1-.176,1.223,10.339,10.339,0,0,1-.826.994.862.862,0,0,0-.112,1.172,11.746,11.746,0,0,0,3.84,3.94c.66.394,1.37.7,2.06,1.046a.781.781,0,0,0,1.064-.231c.4-.505.857-.974,1.24-1.495a.762.762,0,0,1,1.042-.287c1.146.535,2.284,1.086,3.425,1.629a.73.73,0,0,1,.437.749,3.17,3.17,0,0,1-1.3,2.726A5.418,5.418,0,0,1,226.058,181.635Z"
          fill="#f5f5ff"
        />
      </g>
    </svg>
  );
};

export default WhatsAppIcon;
