import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";

export enum AtualizarStatusRelatorioActionKeys {
  FETCH_ATUALIZAR_STATUS_RELATORIO_STARTED = "[RELATORIOS] FETCH_ATUALIZAR_STATUS_RELATORIO_STARTED",
  FETCH_ATUALIZAR_STATUS_RELATORIO_SUCCEEDED = "[RELATORIOS] FETCH_ATUALIZAR_STATUS_RELATORIO_SUCCEEDED",
  FETCH_ATUALIZAR_STATUS_RELATORIO_FAILED = "[RELATORIOS] FETCH_ATUALIZAR_STATUS_RELATORIO_FAILED",
}

export const AtualizarStatusRelatorioActions = {
  fetchAtualizarStatusRelatorio: (
  ): fetchAtualizarStatusRelatorioAction =>
    createAction(
      AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_STARTED
    ),
  fetchAtualizarStatusRelatorioSucceeded:
    (): fetchAtualizarStatusRelatorioSuccededAction =>
      createAction(
        AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_SUCCEEDED
      ),
  fetchAtualizarStatusRelatorioFailed: (
    error: IResponseErrorAPI
  ): fetchAtualizarStatusRelatorioFailedAction =>
    createAction(
      AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_FAILED,
      error
    ),
};

export type AtualizarStatusRelatorioActionUnion = ActionsUnion<
  typeof AtualizarStatusRelatorioActions
>;

export type fetchAtualizarStatusRelatorioAction = Action<
  AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_STARTED
>;

export type fetchAtualizarStatusRelatorioSuccededAction =
  Action<AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_SUCCEEDED>;

export type fetchAtualizarStatusRelatorioFailedAction = Action<
  AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_FAILED,
  IResponseErrorAPI
>;
