import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ShellHost from "./Pages/ShellHost";
import { Provider } from "react-redux";
import { store } from "./Redux";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { OidcProvider } from "redux-oidc";
import userManager from "./Utils/UserManager";
import GlobalStyles from "./Utils/GlobalStyles";
import { FloatingInput } from "./Components/UI/Inputs/FloatingInput";

ReactDOM.render(
  <Provider store={store}>
    <OidcProvider store={store} userManager={userManager}>
      <BrowserRouter>
        <GlobalStyles />
        <FloatingInput />
        <ShellHost />
      </BrowserRouter>
    </OidcProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
