import { ISearch } from "../../../Data/Interfaces/Request/Base/ISearch";
import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { IResponseListAPI } from "../../../Data/Interfaces/Response/Base/IResponseListAPI";
import { IAllRelatorios } from "../../../Data/Interfaces/Response/Relatorios/IAllRelatorios";
import { StatusRelatorio } from "../../../Data/Interfaces/Response/Relatorios/StatusRelatorio";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";

export enum AllRelatoriosActionKeys {
  FETCH_ALL_RELATORIOS_STARTED = "[RELATORIOS] FETCH_ALL_RELATORIOS_STARTED",
  FETCH_ALL_RELATORIOS_SUCCEEDED = "[RELATORIOS] FETCH_ALL_RELATORIOS_SUCCEEDED",
  FETCH_ALL_RELATORIOS_FAILED = "[RELATORIOS] FETCH_ALL_RELATORIOS_FAILED",
}

export const AllRelatoriosActions = {
  fetchRelatorios: (
    data: ISearch,
    identificador?: string,
    statusRelatorio?: StatusRelatorio,
    periodo?: number,
  ): fetchRelatoriosAction =>
    createAction(AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_STARTED, {
      data,
      identificador,
      statusRelatorio,
      periodo,
    }),
  fetchRelatoriosSucceeded: (
    agendamentos: IResponseListAPI<IAllRelatorios>
  ): fetchRelatoriosSuccededAction =>
    createAction(
      AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_SUCCEEDED,
      agendamentos
    ),
  fetchRelatoriosFailed: (
    error: IResponseErrorAPI
  ): fetchRelatoriosFailedAction =>
    createAction(AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_FAILED, error),
};

export type RelatoriosActionUnion = ActionsUnion<typeof AllRelatoriosActions>;

export type fetchRelatoriosAction = Action<
  AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_STARTED,
  { data: ISearch; identificador?: string; statusRelatorio?: StatusRelatorio; periodo?: number; }
>;
export type fetchRelatoriosSuccededAction = Action<
  AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_SUCCEEDED,
  IResponseListAPI<IAllRelatorios>
>;
export type fetchRelatoriosFailedAction = Action<
  AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_FAILED,
  IResponseErrorAPI
>;
