import { call, put } from "redux-saga/effects";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import { fetchRelatoriosAction, AllRelatoriosActions } from "./action";

export function* fetchAllRelatorios(action: fetchRelatoriosAction) {
  try {
    const { data } = yield call(
      RelatoriosAPI.fetchAllRelatorios,
      action.payload.data,
      action.payload.identificador,
      action.payload.statusRelatorio,
      action.payload.periodo
    );

    yield put(AllRelatoriosActions.fetchRelatoriosSucceeded(data));
  } catch (e: any) {
    yield put(AllRelatoriosActions.fetchRelatoriosFailed(e.message));
  }
}
