import { FC, SVGProps } from "react";

const LogoIcon: FC = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 35.314 35.315"
      {...props}
    >
      <g
        id="Grupo_17"
        data-name="Grupo 17"
        transform="translate(-203.761 -100.898)"
      >
        <g id="Grupo_16" data-name="Grupo 16">
          <g id="Grupo_9" data-name="Grupo 9">
            <path
              id="Caminho_5"
              data-name="Caminho 5"
              d="M218.318,131.565a1.436,1.436,0,0,1-.961-2.5,9.981,9.981,0,0,0,2.968-10.051,1.436,1.436,0,1,1,2.775-.743,12.839,12.839,0,0,1-3.822,12.929A1.43,1.43,0,0,1,218.318,131.565Z"
              fill="#fff"
            />
          </g>
          <g id="Grupo_10" data-name="Grupo 10">
            <path
              id="Caminho_6"
              data-name="Caminho 6"
              d="M210.589,122.948a1.436,1.436,0,0,1-1.389-1.077,12.731,12.731,0,0,1,2.252-11.062,13.1,13.1,0,0,1,10.26-5.1c8.036,0,13.266,6.2,13.323,15.794a1.436,1.436,0,0,1-1.428,1.445H233.6a1.437,1.437,0,0,1-1.437-1.428c-.047-7.981-4.052-12.94-10.45-12.94a10.207,10.207,0,0,0-7.989,3.988,9.75,9.75,0,0,0-1.742,8.583,1.438,1.438,0,0,1-1.031,1.751A1.453,1.453,0,0,1,210.589,122.948Z"
              fill="#fff"
            />
          </g>
          <g id="Grupo_11" data-name="Grupo 11">
            <path
              id="Caminho_7"
              data-name="Caminho 7"
              d="M225.6,131.565a1.437,1.437,0,0,1-1.247-2.148,15.536,15.536,0,0,0,1.853-5.256c.32-2,.434-6.358-1.426-8.538a3.782,3.782,0,0,0-3.067-1.295,4.313,4.313,0,0,0-4.313,4.305c0,.091.052.394.089.616.309,1.839,1.031,6.145-3.344,8.614a1.436,1.436,0,1,1-1.412-2.5c2.42-1.366,2.29-3.447,1.924-5.639a6.787,6.787,0,0,1-.13-1.09,7.189,7.189,0,0,1,7.186-7.177,6.664,6.664,0,0,1,5.253,2.3c3.121,3.659,2.193,10.13,2.077,10.856a18.4,18.4,0,0,1-2.194,6.225A1.438,1.438,0,0,1,225.6,131.565Z"
              fill="#fff"
            />
          </g>
          <g id="Grupo_12" data-name="Grupo 12">
            <path
              id="Caminho_8"
              data-name="Caminho 8"
              d="M205.2,111.07a1.436,1.436,0,0,1-1.436-1.436v-1.893c0-4.6,2.239-6.843,6.843-6.843H212.5a1.437,1.437,0,0,1,0,2.873H210.6c-3.042,0-3.97.927-3.97,3.97v1.893A1.436,1.436,0,0,1,205.2,111.07Z"
              fill="#fff"
            />
          </g>
          <g id="Grupo_13" data-name="Grupo 13">
            <path
              id="Caminho_9"
              data-name="Caminho 9"
              d="M212.5,136.213H210.6c-4.6,0-6.843-2.239-6.843-6.844v-1.892a1.437,1.437,0,1,1,2.873,0v1.892c0,3.043.928,3.971,3.97,3.971H212.5a1.437,1.437,0,0,1,0,2.873Z"
              fill="#fff"
            />
          </g>
          <g id="Grupo_14" data-name="Grupo 14">
            <path
              id="Caminho_10"
              data-name="Caminho 10"
              d="M232.232,136.213h-1.893a1.437,1.437,0,0,1,0-2.873h1.893c3.043,0,3.971-.928,3.971-3.971v-1.892a1.436,1.436,0,1,1,2.872,0v1.892C239.075,133.974,236.837,136.213,232.232,136.213Z"
              fill="#fff"
            />
          </g>
          <g id="Grupo_15" data-name="Grupo 15">
            <path
              id="Caminho_11"
              data-name="Caminho 11"
              d="M237.639,111.07a1.436,1.436,0,0,1-1.436-1.436v-1.893c0-3.043-.928-3.97-3.971-3.97h-1.893a1.437,1.437,0,0,1,0-2.873h1.893c4.605,0,6.843,2.238,6.843,6.843v1.893A1.436,1.436,0,0,1,237.639,111.07Z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
