import { IResponseAPI } from "../../../Data/Interfaces/Response/Base/IResponseAPI";
import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { IDashboardConfiguracaoRelatorio } from "../../../Data/Interfaces/Response/Relatorios/IDashboardConfiguracaoRelatorio";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";

export enum ConfiguracoesRelatoriosActionKeys {
  FETCH_CONFIGURACAO_RELATORIOS_STARTED = "[RELATORIOS] FETCH_CONFIGURACAO_RELATORIOS_STARTED",
  FETCH_CONFIGURACAO_RELATORIOS_SUCCEEDED = "[RELATORIOS] FETCH_CONFIGURACAO_RELATORIOS_SUCCEEDED",
  FETCH_CONFIGURACAO_RELATORIOS_FAILED = "[RELATORIOS] FETCH_CONFIGURACAO_RELATORIOS_FAILED",
}

export const ConfiguracoesRelatoriosActions = {
  fetchRelatorios: (lastDays: number = 7, pesquisa: string | undefined = undefined): fetchRelatoriosAction =>
    createAction(
      ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_STARTED,
      { lastDays, pesquisa }
    ),
  fetchRelatoriosSucceeded: (
    agendamentos: IResponseAPI<IDashboardConfiguracaoRelatorio[]>
  ): fetchRelatoriosSuccededAction =>
    createAction(
      ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_SUCCEEDED,
      agendamentos
    ),
  fetchRelatoriosFailed: (
    error: IResponseErrorAPI
  ): fetchRelatoriosFailedAction =>
    createAction(
      ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_FAILED,
      error
    ),
};

export type RelatoriosActionUnion = ActionsUnion<
  typeof ConfiguracoesRelatoriosActions
>;

export type fetchRelatoriosAction =
  Action<ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_STARTED, { lastDays: number, pesquisa: string | undefined }>;
export type fetchRelatoriosSuccededAction = Action<
  ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_SUCCEEDED,
  IResponseAPI<IDashboardConfiguracaoRelatorio[]>
>;
export type fetchRelatoriosFailedAction = Action<
  ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_FAILED,
  IResponseErrorAPI
>;
