import styled from "styled-components";
import { colorClasses, ColorName } from "../../../Utils/Color";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  padding-top: 2rem;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 70%;
  height: calc(100vh - 20vh);
`;

export const TitlePage = styled.h2`
  font-weight: 700;
  margin: 0px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${colorClasses["bgHeader"]};
  border-radius: 0.4rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  padding: 1rem;

  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;

export const Title = styled.span`
  a {
    color: ${colorClasses["titleGray"]};
    text-decoration: none;
    cursor: pointer;
  }
`;

export const Status = styled.span<{ color: ColorName }>`
  text-transform: uppercase;
  font-weight: 500;
  color: ${(props) => colorClasses[props.color]};
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 70%;
`;

export const Rodape = styled.div`
  margin-top: 4rem;
`;
