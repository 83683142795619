import { FC } from "react";
import { useDispatch } from "react-redux";
import { NovoRelatorioActions } from "../../../Redux/Relatorios/Novo/action";
import { FileHelper } from "../../../Utils/File";

const NovoRelatorio: FC = () => {
  const dispatch = useDispatch();

  const onSubmit = async (event: any) => {
    event.preventDefault();
    console.log(event);
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);
    const fileDocBase64 = await FileHelper.toBase64(formProps.foto_doc as any);
    const filePerfilBase64 = await FileHelper.toBase64(
      formProps.foto_perfil as any
    );

    dispatch(
      NovoRelatorioActions.fetchNovoRelatorio({
        identificador: (formProps.identificador as string) || "",
        parametros: {
          imagem_Documento: (fileDocBase64 as string)
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          imagem_Perfil: (filePerfilBase64 as string)
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
        },
      })
    );
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <input name="identificador" required type="text" />

        <input name="foto_doc" required type="file" />
        <input name="foto_perfil" type="file" />

        <input type="submit" value="Enviar" />
      </form>
    </div>
  );
};

export default NovoRelatorio;
