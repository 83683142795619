import axios, { AxiosInstance } from "axios";
import { securityService } from "./SecurityService";

export class Http {
  static async axios(): Promise<AxiosInstance> {
    let headers: any = {
      "Content-type": "application/json",
    };

    if (securityService.isAuthenticated()) {
      headers = {
        ...headers,
        Authorization: `Bearer ${securityService.getToken()}`,
      };
    }

    const timeout = 120 * 1000;
    const instance = axios.create({
      headers,
      timeout: timeout,
      baseURL: process.env.REACT_APP_API_URL,
    });

    instance.interceptors.response.use(undefined, (err) => {
      const response = err.response || null;
      if (!response) {
        return Promise.reject(err);
      } else {
        const data = response.data || null;
        if (!data) {
          return Promise.reject(err);
        } else {
          return Promise.reject(data);
        }
      }
    });

    return instance;
  }
}
