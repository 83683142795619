import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";

const AuthCallBack: FC = () => {
  const params = useParams();

  useEffect(() => {
    const ambiente = params.ambiente;

    if (!ambiente) {
      localStorage.removeItem("ongo_ambiente");
    } else {
      localStorage.setItem("ongo_ambiente", ambiente || "");
    }

    window.location.href = "/";
  }, [params]);

  return null;
};

export default AuthCallBack;
