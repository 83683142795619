import { createAction, Action, ActionsUnion } from "../../actionHelpers";
import { IOpenFloatInput } from "./IFloatInputFormState";

export enum FloatInputFormActionKeys {
  FETCH_FLOAT_FORM_INPUT_OPEN = "[FORM] FETCH_FLOAT_FORM_INPUT_OPEN",
  FETCH_FLOAT_FORM_INPUT_CLOSE = "[FORM] FETCH_FLOAT_FORM_INPUT_CLOSE",
}

export const FloatInputFormActions = {
  fetchOpenFloatInputForm: (
    data: IOpenFloatInput
  ): fetchOpenFloatInputFormAction =>
    createAction(FloatInputFormActionKeys.FETCH_FLOAT_FORM_INPUT_OPEN, data),
  fetchCloseFloatInputForm: (): fetchCloseFloatInputFormAction =>
    createAction(FloatInputFormActionKeys.FETCH_FLOAT_FORM_INPUT_CLOSE),
};

export type FloatInputFormActionUnion = ActionsUnion<
  typeof FloatInputFormActions
>;

export type fetchOpenFloatInputFormAction = Action<
  FloatInputFormActionKeys.FETCH_FLOAT_FORM_INPUT_OPEN,
  IOpenFloatInput
>;
export type fetchCloseFloatInputFormAction =
  Action<FloatInputFormActionKeys.FETCH_FLOAT_FORM_INPUT_CLOSE>;
