import styled from "styled-components";
import { colorClasses } from "../../../../Utils/Color";

export const Container = styled.div``;

export const Content = styled.div`
  position: relative;
  padding-top: 5px;
`;

export const InputStyle = styled.input`
  padding: 0.625rem 2rem 0.625rem 1rem;
  border: 1px solid ${colorClasses["bgHeader"]};
  border-radius: 2px;
  color: ${colorClasses["default"]};
  resize: none;
  width: calc(100% - 42px);
`;
