import { call, put, select } from "redux-saga/effects";
import { RootState } from "../..";
import { IDetalhesRelatorioState } from "../Detalhes/IDetalhesRelatorioState";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import {
  AtualizarStatusRelatorioActions,
} from "./action";

export function* fetchAtualizarStatusRelatorio(
) {
  try {
    const stateDadosRelatorio = (root: RootState) => root.relatorios.dados;
    const {
      result: { data },
    }: IDetalhesRelatorioState = yield select(stateDadosRelatorio);

    yield call(RelatoriosAPI.fetchUpdateStatus, data.id);

    yield put(
      AtualizarStatusRelatorioActions.fetchAtualizarStatusRelatorioSucceeded()
    );
  } catch (e: any) {
    yield put(
      AtualizarStatusRelatorioActions.fetchAtualizarStatusRelatorioFailed(e.message)
    );
  }
}
