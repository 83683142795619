import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../Redux";
import { FloatInputFormActions } from "../../../../Redux/Form/FloatInput/action";
import RelatorioHelper from "../../../../Utils/RelatorioHelper";
import { PrimaryButton } from "../../Buttons";
import { TextInputState } from "../TextInput";

export const FloatingInput: FC = () => {
  const [inputVal, setInputVal] = useState<any>(undefined);

  const dispatch = useDispatch();
  const { isOpen, data } = useSelector((root: RootState) => {
    return {
      isOpen: root.form.floatInput.isOpen,
      data: root.form.floatInput.data,
    };
  });

  useEffect(() => {
    setInputVal(data.inputValue);
  }, [data, isOpen]);

  const closeDialog = useCallback(() => {
    data.onClose && data.onClose();
    dispatch(FloatInputFormActions.fetchCloseFloatInputForm());
  }, [dispatch, data]);

  const saveAction = useCallback(() => {
    data.onSave && data.onSave(inputVal);
    dispatch(FloatInputFormActions.fetchCloseFloatInputForm());
  }, [inputVal, dispatch, data]);

  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>
        Valor atual: {RelatorioHelper.parsePropValue(data.inputValue)}
      </DialogTitle>
      <DialogContent>
        <TextInputState
          label="Valor"
          name="Valor"
          initialVal={inputVal}
          onChangeText={(val) => {
            setInputVal(val);
          }}
        />
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={() => saveAction()}>Salvar</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
