import { FC, SVGProps } from "react";

const MenuIcon: FC = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 33.165 32.158"
      className={props.className}
      {...props}
    >
      <g
        id="Grupo_8"
        data-name="Grupo 8"
        transform="translate(-204.836 -36.799)"
      >
        <g id="Grupo_4" data-name="Grupo 4">
          <rect
            id="Retângulo_3"
            data-name="Retângulo 3"
            width="33.165"
            height="2.313"
            transform="translate(204.836 36.799)"
            fill={props.fill}
          />
        </g>
        <g id="Grupo_5" data-name="Grupo 5">
          <rect
            id="Retângulo_4"
            data-name="Retângulo 4"
            width="33.165"
            height="2.313"
            transform="translate(204.836 46.748)"
            fill={props.fill}
          />
        </g>
        <g id="Grupo_6" data-name="Grupo 6">
          <rect
            id="Retângulo_5"
            data-name="Retângulo 5"
            width="33.165"
            height="2.313"
            transform="translate(204.836 56.696)"
            fill={props.fill}
          />
        </g>
        <g id="Grupo_7" data-name="Grupo 7">
          <rect
            id="Retângulo_6"
            data-name="Retângulo 6"
            width="33.165"
            height="2.313"
            transform="translate(204.836 66.644)"
            fill={props.fill}
          />
        </g>
      </g>
    </svg>
  );
};

export default MenuIcon;
