import { Props, PropsMenu } from "./types";
import styled from "styled-components";
import EmailIcon from "../Icons/email";
import LogoIcon from "../Icons/logo";
import WhatsAppIcon from "../Icons/whatsApp";
import MenuIcon from "../Icons/menu";

export const Sidebar = styled.aside<Props>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 50px;
  height: 100vh;
  background-color: ${({ isOpen }) =>
    isOpen === false ? "transparent" : "#473799"};
  transition: 300ms all ease-in-out;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
`;

export const List = styled.div<Props>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  transition: 300ms all ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen === true ? "translateX(0)" : "translateX(-200%)"};
`;

export const Item = styled.div`
  margin: 10px 0;
  cursor: pointer;
`;

export const Email = styled(EmailIcon)``;

export const WhatsApp = styled(WhatsAppIcon)``;

export const Logo = styled(LogoIcon)``;

export const Menu = styled(MenuIcon)<PropsMenu>`
  cursor: pointer;
  transition: 300ms all ease-in-out;
  fill: ${({ isOpen }) => (isOpen === false ? "#473799" : "white")};
`;
