import decode from "jwt-decode";
import { getState } from "./../Redux/index";

const SESSION_KEY = "state";

export interface ISession {
  token: string;
  expiration: Date;
}

export class SecurityService {
  /**
   * Returns the bearer auth token.
   */
  getToken(): string | undefined {
    const local = this.getSession();
    return local ? local.token : undefined;
  }

  /**
   * Returns the user id from bearer auth token.
   */
  getUserId(): string | undefined {
    if (this.isAuthenticated()) {
      const local = this.getSession();
      if (local && local.token) {
        return this.getUserIdToken(local.token);
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }

  getUserIdToken(token: string): string | undefined {
    if (token) {
      const decodedToken: any = decode(token);

      return decodedToken ? decodedToken["sub"] : undefined;
    } else {
      return undefined;
    }
  }

  getUserOrganizacao(token: string): string | undefined {
    if (token) {
      const decodedToken: any = decode(token);

      return decodedToken ? decodedToken["Organizacao"] : undefined;
    } else {
      return undefined;
    }
  }

  /**
   * Indicates if the user is authenticated.
   */
  isAuthenticated() {
    return !!this.getToken();
  }

  getSession = (): ISession | undefined => {
    const user = getState().oidc.user;
    const expiration = new Date();
    if (user) {
      expiration.setSeconds(user.expires_at);
      return {
        token: user.access_token,
        expiration: expiration,
      };
    }

    return undefined;
  };

  getName = (): string => {
    const session = getState().oidc.user;
    const name = session?.profile.name || "";
    return name;
  };
}

export const securityService = new SecurityService();
