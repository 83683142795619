import React, { FC } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { RootState } from "../../Redux";
import Login from "../Auth";
import AuthCallBack from "../Auth/AuthCallBack";
import LogoutCallBack from "../Auth/LogoutCallBack";
import TrocaAmbiente from "../Auth/TrocaAmbiente";
import Dashboard from "../Dashboard";
import DetalhesRelatorio from "../Relatorios/Detalhes";
import ListaRelatorios from "../Relatorios/Lista";
import NovoRelatorio from "../Relatorios/Novo";

const MainSwitch: FC = () => {
  let location = useLocation();
  const { login } = useSelector((states: RootState) => {
    return {
      login: states.oidc,
    };
  });

  let state = location.state as { backgroundLocation?: Location };

  return (
    <Routes location={state?.backgroundLocation || location}>
      {login.user?.access_token !== undefined && !login.isLoadingUser && (
        <>
          <Route path={"/relatorios"} element={<Dashboard />} />
          <Route
            path={"/relatorios/detail/:identificador/:status/:periodo"}
            element={<ListaRelatorios />}
          />
          <Route
            path={
              "/relatorios/detail/:identificador/:status/:periodo/dados/:idRelatorio"
            }
            element={<DetalhesRelatorio />}
          />
          <Route path={"/relatorios/novo"} element={<NovoRelatorio />} />
          <Route path={"*"} element={<Navigate to="/relatorios" />} />
        </>
      )}

      {login.user?.access_token === undefined && !login.isLoadingUser && (
        <>
          <Route path={"/login"} element={<Login />} />
          <Route path={"*"} element={<Navigate to="/login" />} />
        </>
      )}

      <Route path={"/oauthredirect"} element={<AuthCallBack />} />
      <Route path={"/logoutredirect"} element={<LogoutCallBack />} />
      <Route path={"/troca-ambiente/:ambiente"} element={<TrocaAmbiente />} />
    </Routes>
  );
};

export default MainSwitch;
