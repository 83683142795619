import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import { rootSaga } from './rootSagas';
import { loadUser } from "redux-oidc";
import userManager from '../Utils/UserManager';

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);

  const getState = store.getState;

  return { store, getState };
};

export type RootState = ReturnType<typeof rootReducer>;

export const { store, getState } = configureStore();

loadUser(store, userManager);