import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";

export enum ProcessarRelatorioActionKeys {
  FETCH_PROCESSAR_RELATORIO_STARTED = "[RELATORIOS] FETCH_PROCESSAR_RELATORIO_STARTED",
  FETCH_PROCESSAR_RELATORIO_SUCCEEDED = "[RELATORIOS] FETCH_PROCESSAR_RELATORIO_SUCCEEDED",
  FETCH_PROCESSAR_RELATORIO_FAILED = "[RELATORIOS] FETCH_PROCESSAR_RELATORIO_FAILED",
}

export const ProcessarRelatorioActions = {
  fetchProcessarRelatorio: (
  ): fetchProcessarRelatorioAction =>
    createAction(
      ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_STARTED
    ),
  fetchProcessarRelatorioSucceeded:
    (): fetchProcessarRelatorioSuccededAction =>
      createAction(
        ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_SUCCEEDED
      ),
  fetchProcessarRelatorioFailed: (
    error: IResponseErrorAPI
  ): fetchProcessarRelatorioFailedAction =>
    createAction(
      ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_FAILED,
      error
    ),
};

export type ProcessarRelatorioActionUnion = ActionsUnion<
  typeof ProcessarRelatorioActions
>;

export type fetchProcessarRelatorioAction = Action<
  ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_STARTED
>;

export type fetchProcessarRelatorioSuccededAction =
  Action<ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_SUCCEEDED>;

export type fetchProcessarRelatorioFailedAction = Action<
  ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_FAILED,
  IResponseErrorAPI
>;
