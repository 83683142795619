import { Pagination, Stack } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import OneSignalReact from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Loading } from "../../../Components/UI";
import { PeriodoDate } from "../../../Components/UI/Inputs";
import { getNomeFromStatus } from "../../../Data/Interfaces/Response/Relatorios/StatusRelatorio";
import { RootState } from "../../../Redux";
import { AllRelatoriosActions } from "../../../Redux/Relatorios/All/action";
import { isValidQueryParam, useQuery } from "../../../Utils/Url";
import {
  Actions,
  Body,
  Box,
  Container,
  Content,
  Rodape,
  Title,
  TitlePage,
} from "./styles";

const ListaRelatorios: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const query = useQuery();
  const [periodo, setPeriodo] = useState<number>(+(params.periodo || 0));

  const {
    relatorios: {
      result: { data },
      isLoading,
    },
  } = useSelector((states: RootState) => {
    return {
      relatorios: states.relatorios.all,
    };
  });

  const loadRelatorios = (pageIndex: number = 0) => {
    dispatch(
      AllRelatoriosActions.fetchRelatorios(
        {
          pageIndex,
          pesquisa: isValidQueryParam(query.get("pesquisa")),
        },
        params.identificador,
        +(params.status || 0),
        periodo
      )
    );
  };

  useEffect(() => {
    OneSignalReact.addListenerForNotificationOpened((event) => {
      loadRelatorios(0);
    });
  }, []);

  useEffect(() => {
    loadRelatorios(0);
  }, [
    params.identificador,
    query.get("pesquisa"),
    params.status,
    periodo,
    dispatch,
  ]);

  return (
    <Loading isLoading={isLoading}>
      <Container>
        <Content>
          <TitlePage>
            Relatórios {getNomeFromStatus(+(params.status || 0))}
          </TitlePage>
          <Actions>
            <PeriodoDate
              onSelectItem={(val) => {
                console.log(val);
                setPeriodo(+val);
              }}
              name="Periodo"
              options={[
                {
                  name: "Últimas 24 horas",
                  value: 0,
                },
                {
                  name: "Últimos 7 dias",
                  value: 7,
                },
                {
                  name: "Últimos 30 dias",
                  value: 30,
                },
                {
                  name: "Últimos 90 dias",
                  value: 90,
                },
              ]}
              value={periodo}
            />
          </Actions>
          <Body>
            {data.data?.map((x) => {
              return (
                <Box key={`box-${x.identificador}`}>
                  <Title>
                    <Link to={`dados/${x.identificador}`}>
                      {x.nomeDocumento || "Documento não detectado"}
                    </Link>
                  </Title>
                </Box>
              );
            })}
          </Body>
          <Rodape>
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(data.totalResult / data.pageSize)}
                page={data.pageIndex + 1}
                onChange={(event, value: number) => {
                  if (value > 0) {
                    loadRelatorios(value - 1);
                  }
                }}
                shape="rounded"
              />
            </Stack>
          </Rodape>
        </Content>
      </Container>
    </Loading>
  );
};

export default ListaRelatorios;
