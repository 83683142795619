import { IResponseAPI } from "../../../Data/Interfaces/Response/Base/IResponseAPI";
import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import {
  IDadosRelatorio,
  IFotosDadosRelatorio,
} from "../../../Data/Interfaces/Response/Relatorios/IDadosRelatorio";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";

export enum DetalhesRelatorioActionKeys {
  FETCH_DETALHES_RELATORIOS_STARTED = "[DETALHES_RELATORIOS] FETCH_DETALHES_RELATORIOS_STARTED",
  FETCH_DETALHES_RELATORIOS_SUCCEEDED = "[DETALHES_RELATORIOS] FETCH_DETALHES_RELATORIOS_SUCCEEDED",
  FETCH_DETALHES_RELATORIOS_SUCCEEDED_CUSTOM = "[DETALHES_RELATORIOS] FETCH_DETALHES_RELATORIOS_SUCCEEDED_CUSTOM",
  FETCH_DETALHES_RELATORIOS_FAILED = "[DETALHES_RELATORIOS] FETCH_DETALHES_RELATORIOS_FAILED",
}

export const DetalhesRelatorioActions = {
  fetchDetalhesRelatorio: (id: string): fetchDetalhesRelatorioAction =>
    createAction(
      DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_STARTED,
      id
    ),
  fetchDetalhesRelatorioSucceeded: (
    data: IResponseAPI<IDadosRelatorio>,
    fotos: IResponseAPI<IFotosDadosRelatorio>
  ): fetchDetalhesRelatorioSuccededAction =>
    createAction(
      DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_SUCCEEDED,
      {
        ...data,
        data: {
          ...data.data,
          fotoDocumento: fotos.data.fotoDocumento,
          fotoPerfil: fotos.data.fotoPerfil,
        },
      }
    ),
  fetchDetalhesRelatorioCustom: (
    data: IDadosRelatorio
  ): fetchDetalhesRelatorioCustomAction =>
    createAction(
      DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_SUCCEEDED_CUSTOM,
      data
    ),
  fetchDetalhesRelatorioFailed: (
    error: IResponseErrorAPI
  ): fetchDetalhesRelatorioFailedAction =>
    createAction(
      DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_FAILED,
      error
    ),
};

export type DetalhesRelatorioActionUnion = ActionsUnion<
  typeof DetalhesRelatorioActions
>;

export type fetchDetalhesRelatorioAction = Action<
  DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_STARTED,
  string
>;
export type fetchDetalhesRelatorioSuccededAction = Action<
  DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_SUCCEEDED,
  IResponseAPI<IDadosRelatorio>
>;
export type fetchDetalhesRelatorioCustomAction = Action<
  DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_SUCCEEDED_CUSTOM,
  IDadosRelatorio
>;
export type fetchDetalhesRelatorioFailedAction = Action<
  DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_FAILED,
  IResponseErrorAPI
>;
