import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  margin-top: 7vh;
`;

export const RefreshPage = styled.div`
  position: absolute;
  right: 0px;
  padding-right: 10px;
  padding-top: 10px;
  bottom: auto;
`;
