import React, { FC } from "react";
import { CustomButton } from "./styles";
import { ButtonProps } from "@material-ui/core";
import { ColorName } from "../../../../Utils/Color";

interface ICustomButton extends ButtonProps {
  icon?: React.ReactNode;
  customColor?: ColorName;
}

const PrimaryButton: FC<ICustomButton> = ({ children, ...props }) => {
  return (
    <CustomButton {...props}>
      {props.icon}
      {children}
    </CustomButton>
  );
};

export default PrimaryButton;
