import { all, takeEvery, takeLatest } from "redux-saga/effects";
import { AllRelatoriosActionKeys } from "./All/action";
import { fetchAllRelatorios } from "./All/sagas";
import { AprovaManualRelatorioActionKeys } from "./AprovaManualRelatorio/action";
import {
  fetchAprovaManualRelatorio,
  fetchEditDadosRelatorio,
} from "./AprovaManualRelatorio/sagas";
import { AtualizarStatusRelatorioActionKeys } from "./AtualizarStatus/action";
import { fetchAtualizarStatusRelatorio } from "./AtualizarStatus/sagas";
import { ConfiguracoesRelatoriosActionKeys } from "./Configuracoes/action";
import { fetchConfiguracoesRelatorios } from "./Configuracoes/sagas";
import { DetalhesRelatorioActionKeys } from "./Detalhes/action";
import { fetchDetalhesRelatorio } from "./Detalhes/sagas";
import { NovoRelatorioActionKeys } from "./Novo/action";
import { fetchNovoRelatorio } from "./Novo/sagas";
import { ProcessarRelatorioActionKeys } from "./ProcessarRelatorio/action";
import { fetchProcessarRelatorio } from "./ProcessarRelatorio/sagas";
import { RespostaManualConsultaActionKeys } from "./RespostaManualConsulta/action";
import { fetchRespostaManualConsulta } from "./RespostaManualConsulta/sagas";

export function* relatorios() {
  yield all([
    takeLatest(
      AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_STARTED,
      fetchAllRelatorios
    ),
    takeLatest(
      ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_STARTED,
      fetchConfiguracoesRelatorios
    ),
    takeLatest(
      NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_STARTED,
      fetchNovoRelatorio
    ),
    takeLatest(
      DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_STARTED,
      fetchDetalhesRelatorio
    ),
    takeEvery(
      RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_STARTED,
      fetchRespostaManualConsulta
    ),
    takeLatest(
      AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_STARTED,
      fetchAprovaManualRelatorio
    ),
    takeLatest(
      AprovaManualRelatorioActionKeys.FETCH_EDIT_DADOS_RELATORIO,
      fetchEditDadosRelatorio
    ),
    takeLatest(ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_STARTED, fetchProcessarRelatorio),
    takeLatest(AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_STARTED, fetchAtualizarStatusRelatorio)
  ]);
}
