import { combineReducers } from "redux";
import relatorios from "./Relatorios/reducer";
import form from "./Form/reducer";
import { reducer as oidcReducer } from "redux-oidc";

export const rootReducer = combineReducers({
  relatorios,
  form,
  oidc: oidcReducer,
});
