import { FC, useEffect } from "react";
import { securityService } from "../../Utils/SecurityService";
import { useDispatch, useSelector } from "react-redux";
import { isValidQueryParam, useQuery } from "../../Utils/Url";
import { ConfiguracoesRelatoriosActions } from "../../Redux/Relatorios/Configuracoes/action";
import { RootState } from "../../Redux";
import { useNavigate } from "react-router-dom";
import * as Styled from "./styles";
import logoPng from "./../../Static/Images/Logo.png";
import userManager from "../../Utils/UserManager";

const Header: FC = () => {
  const name = securityService.getName();

  const handleExit = async () => {
    localStorage.setItem("isSignout", "true");
    await userManager.signoutRedirect();
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();

  const { periodo } = useSelector((state: RootState) => {
    return {
      periodo: state.relatorios.periodo.periodo,
    };
  });

  useEffect(() => {
    const pesquisa = isValidQueryParam(query.get("pesquisa"));
    dispatch(ConfiguracoesRelatoriosActions.fetchRelatorios(periodo, pesquisa));
  }, [dispatch, periodo, query.get("pesquisa")]);

  return (
    <Styled.Container>
      <Styled.CenterSide>
        <Styled.InputGroup>
          <Styled.SearchIcon />
          <Styled.InputSearch
            label=""
            name="pesquisa"
            placeHolder="Nome, CPF, CNPJ, RG..."
            initialVal={isValidQueryParam(query.get("pesquisa"))}
            onChangeText={(pesquisa) => {
              navigate(`/relatorios?pesquisa=${pesquisa}`);
            }}
          />
        </Styled.InputGroup>
        <Styled.SelectMenu id="selectMenu" value={name}>
          <Styled.MenuOption value={name} key={name}>
            {name}
          </Styled.MenuOption>
          <Styled.MenuOption
            value={"exit"}
            key="exit"
            onClick={() => handleExit()}
          >
            Sair
          </Styled.MenuOption>
        </Styled.SelectMenu>
      </Styled.CenterSide>
      <Styled.RightSide>
        <Styled.Logo src={logoPng} />
      </Styled.RightSide>
    </Styled.Container>
  );
};

export default Header;
