import moment from "moment";

export default class RelatorioHelper {
  static getPropType = (
    value: any
  ): "text" | "number" | "date" | "boolean" | "array" | undefined => {
    if (
      typeof value === "string" &&
      !moment(value, moment.ISO_8601, true).isValid()
    ) {
      return "text";
    } else if (typeof value === "number" || typeof value === "bigint") {
      return "number";
    } else if (
      typeof value === "string" &&
      moment(value, moment.ISO_8601, true).isValid()
    ) {
      return "date";
    } else if (typeof value === "boolean") {
      return "boolean";
    } else if (Array.isArray(value)) {
      return "array";
    }
  };
  static parsePropValue = (value: any) => {
    const valType = this.getPropType(value);
    if (valType === "text") {
      return value || "Não informado";
    } else if (valType === "number") {
      return value || "Não informado";
    } else if (valType === "date") {
      return (value && moment(value).format("DD/MM/YYYY")) || "Não informado";
    } else if (valType === "boolean") {
      return value ? "Sim" : "Não";
    } else if (valType === "array") {
      return value?.join(", ") || "Não Informado";
    }
  };
}
