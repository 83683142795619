import { call, put } from "redux-saga/effects";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import {
  fetchRelatoriosAction,
  ConfiguracoesRelatoriosActions,
} from "./action";

export function* fetchConfiguracoesRelatorios(action: fetchRelatoriosAction) {
  try {
    const { data } = yield call(RelatoriosAPI.fetchConfiguracoesRelatorios, action.payload.lastDays, action.payload.pesquisa);

    yield put(ConfiguracoesRelatoriosActions.fetchRelatoriosSucceeded(data));
  } catch (e: any) {
    yield put(ConfiguracoesRelatoriosActions.fetchRelatoriosFailed(e.message));
  }
}
