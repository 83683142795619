import styled from 'styled-components';
import { colorClasses, ColorName } from '../../Utils/Color';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border: 1px solid ${colorClasses['bgHeader']};
    min-height: 20rem;
    border-radius: 0.4rem;
    padding-top: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid ${colorClasses['bgHeader']};
    min-width: 20rem;
    padding-bottom: 20px;
`;

export const Logo = styled.img`
    object-fit: fill;
    height: auto;
    width: 50%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    width: 70%;
`;

export const TitlePage = styled.h2`
    font-weight: 700;    
`;

export const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: -1rem;
`;

export const Title = styled.p`
    padding-bottom: 1rem;
    width: 100%;
    border-bottom: 1px solid ${colorClasses['bgHeader']};

    span {
        padding-left: 1rem;
        color: ${colorClasses['titleGray']}
    }
`;

export const Items = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    align-content: center;
`;

export const Item = styled.div`
    a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        align-content: center;
        align-items: center;
        color: inherit;
    }
    
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1rem;
    cursor: pointer;

    &:not(:last-child) {
        border-right: 1px solid ${colorClasses['bgHeader']};
    }
`;

export const Quantidade = styled.span<{ color: ColorName }>`
    font-size: 2rem;
    font-weight: bold;
    color: ${(props) => colorClasses[props.color]};
`;

export const Status = styled.span`
    text-transform: uppercase;
    font-weight: 500;
`;