import { FC, useEffect } from "react";
import { Logo, Box, Container } from "./styles";
import logoPng from "./../../Static/Images/Logo.png";
import userManager from "../../Utils/UserManager";
import { CallbackComponent } from "redux-oidc";
import { useNavigate } from "react-router-dom";

const AuthCallBack: FC = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <CallbackComponent
        userManager={userManager}
        successCallback={(user) => {
          navigate("/relatorios");
        }}
        errorCallback={(err) => console.log(err)}
      >
        <Box>
          <Logo src={logoPng} />

          <p>Autenticando....</p>
        </Box>
      </CallbackComponent>
    </Container>
  );
};

export default AuthCallBack;
