import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";
import { IResponseAPI } from "../../../Data/Interfaces/Response/Base/IResponseAPI";
import { INovoRelatorio } from "../../../Data/Interfaces/Request/Relatorio/INovoRelatorio";
import { INovoRelatorioResponse } from "../../../Data/Interfaces/Response/Relatorios/INovoRelatorioResponse";

export enum NovoRelatorioActionKeys {
  FETCH_NOVO_RELATORIO_STARTED = "[RELATORIOS] FETCH_NOVO_RELATORIO_STARTED",
  FETCH_NOVO_RELATORIO_SUCCEEDED = "[RELATORIOS] FETCH_NOVO_RELATORIO_SUCCEEDED",
  FETCH_NOVO_RELATORIO_FAILED = "[RELATORIOS] FETCH_NOVO_RELATORIO_FAILED",
}

export const NovoRelatorioActions = {
  fetchNovoRelatorio: (data: INovoRelatorio): fetchNovoRelatorioAction =>
    createAction(NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_STARTED, data),
  fetchNovoRelatorioSucceeded: (
    agendamentos: IResponseAPI<INovoRelatorioResponse>
  ): fetchNovoRelatorioSuccededAction =>
    createAction(
      NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_SUCCEEDED,
      agendamentos
    ),
  fetchNovoRelatorioFailed: (
    error: IResponseErrorAPI
  ): fetchNovoRelatorioFailedAction =>
    createAction(NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_FAILED, error),
};

export type NovoRelatorioActionUnion = ActionsUnion<
  typeof NovoRelatorioActions
>;

export type fetchNovoRelatorioAction = Action<
  NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_STARTED,
  INovoRelatorio
>;
export type fetchNovoRelatorioSuccededAction = Action<
  NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_SUCCEEDED,
  IResponseAPI<INovoRelatorioResponse>
>;
export type fetchNovoRelatorioFailedAction = Action<
  NovoRelatorioActionKeys.FETCH_NOVO_RELATORIO_FAILED,
  IResponseErrorAPI
>;
