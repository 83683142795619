import styled from "styled-components";
import { colorClasses, ColorName } from "../../../Utils/Color";

export const Container = styled.div`
  width: 95%;
  margin: 0 auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export const Title = styled.h1<{ color?: ColorName }>`
  color: ${(props) => colorClasses[props.color || "default"]};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
`;

export const Box = styled.div`
  &:first-child {
    width: 20%;
  }
  &:last-child {
    width: 80%;
  }
`;

export const List = styled.ul`
  list-style: circle;
  padding: 10px;
`;

export const Item = styled.li`
  cursor: pointer;
  padding: 10px 5px;
  font-weight: bold;
  transition: 200ms all linear;
  &:hover {
    color: #473799;
  }
`;

export const BoxImages = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
`;

export const ConfigImage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const TittleImage = styled.h3`
  color: #473799;
`;

export const Image = styled.img`
  align-self: center;
  object-fit: fill;
  max-height: 20rem;
  padding-bottom: 4rem;
  width: auto;
  cursor: pointer;

  -webkit-transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;

  &:hover {
    margin-top: 5rem;
    -webkit-transform: scale(2.6);
    transform: scale(2.6);
  }
`;

export const MainIFrame = styled.div`
  border-radius: 5px 5px 0 0;
  width: 100%;
  min-height: 500px;
  height: auto;
  z-index: 30;
  position: relative;
  overflow: hidden !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  z-index: 0;
`;

export const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const DataDocuments = styled.div``;
