import {
  ConfiguracoesRelatoriosActionKeys,
  RelatoriosActionUnion,
} from "./action";
import { IConfiguracoesRelatoriosState } from "./IConfiguracoesRelatoriosState";

const initialState: IConfiguracoesRelatoriosState = {
  result: {
    data: [],
    success: true,
  },
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: RelatoriosActionUnion
): IConfiguracoesRelatoriosState => {
  switch (action.type) {
    case ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_SUCCEEDED:
      return {
        ...state,
        result: {
          ...action.payload,
          data: action.payload.data || []
        },
        isLoading: false,
      };
    case ConfiguracoesRelatoriosActionKeys.FETCH_CONFIGURACAO_RELATORIOS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
