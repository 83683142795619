import { call, put } from "redux-saga/effects";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import {
  fetchRespostaManualConsultaAction,
  RespostaManualConsultaActions,
} from "./action";

export function* fetchRespostaManualConsulta(
  action: fetchRespostaManualConsultaAction
) {
  try {
    yield call(RelatoriosAPI.fetchRespostaManualConsulta, action.payload);

    yield put(
      RespostaManualConsultaActions.fetchRespostaManualConsultaSucceeded()
    );
  } catch (e: any) {
    yield put(
      RespostaManualConsultaActions.fetchRespostaManualConsultaFailed(e.message)
    );
  }
}
