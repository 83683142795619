import React, { FC, ReactNode } from "react";
import { CircularProgress } from "@material-ui/core";

export interface ILoading {
  isLoading: boolean;
  className?: String;
  children: ReactNode;
}

export const Loading: FC<ILoading> = ({ isLoading, children, className }) => {
  return isLoading ? (
    <div>
      <div className={`${className}`} style={{ opacity: 0.3 }}>
        {children}
      </div>
      <CircularProgress
        style={{ position: "absolute", top: "50%", left: "50%" }}
      />
    </div>
  ) : (
    <>{children}</>
  );
};
