import { PeriodoActionUnion, PeriodoActionKeys } from "./Action";

interface IPeriodo {
  periodo: number;
}

const initialState: IPeriodo = {
  periodo: 0,
};

const PeriodoReducer = (
  state = initialState,
  action: PeriodoActionUnion
): IPeriodo => {
  switch (action.type) {
    case PeriodoActionKeys.FETCH_PERSISTENCIA_PERIODO_SUCCEEDED:
      return {
        ...state,
        periodo: action.payload,
      };
    case PeriodoActionKeys.FETCH_PERSISTENCIA_PERIODO_FAILED:
      return {
        ...state,
        periodo: 0,
      };
    default:
      return state;
  }
};

export default PeriodoReducer;
