import { FC, SVGProps } from "react";

const EmailIcon: FC = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 39.601 28.063"
      {...props}
    >
      <path
        id="Caminho_45"
        data-name="Caminho 45"
        d="M239.671,210.424H203.165a1.547,1.547,0,0,0-1.547,1.547V236.94a1.547,1.547,0,0,0,1.547,1.547h36.506a1.548,1.548,0,0,0,1.548-1.547V211.971A1.548,1.548,0,0,0,239.671,210.424Zm-1.547,24.968H204.712V222.135l16.413,3.164a1.547,1.547,0,0,0,.586,0l16.413-3.164Zm0-16.409L221.418,222.2l-16.706-3.221v-5.464h33.412Z"
        transform="translate(-201.618 -210.424)"
        fill="#f5f5ff"
      />
    </svg>
  );
};

export default EmailIcon;
