import {
  DetalhesRelatorioActionKeys,
  DetalhesRelatorioActionUnion,
} from "./action";
import { IDetalhesRelatorioState } from "./IDetalhesRelatorioState";

const initialState: IDetalhesRelatorioState = {
  result: {
    data: {} as any,
    success: true,
  },
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: DetalhesRelatorioActionUnion
): IDetalhesRelatorioState => {
  switch (action.type) {
    case DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_SUCCEEDED_CUSTOM:
      return {
        ...state,
        result: {
          ...state.result,
          data: action.payload,
        },
        isLoading: false,
      };
    case DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_SUCCEEDED:
      return {
        ...state,
        result: {
          ...action.payload,
          data: action.payload.data || [],
        },
        isLoading: false,
      };
    case DetalhesRelatorioActionKeys.FETCH_DETALHES_RELATORIOS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
