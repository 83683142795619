import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const Main = styled.div``;

export const Header = styled.div`
  background-color: #473799;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonActions = styled.div`
  padding: 10px;
`;

export const Tittle = styled.p`
  font-weight: bold;
  color: #fff;
`;

export const Body = styled.div`
  padding: 0 20px;
  border: 1px solid #473799;
`;

export const Wrapper = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px solid #473799;
  height: 7vh;
  &:last-child {
    border-bottom: none;
  }
`;

export const Label = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #473799;
  padding: 0 20px;
`;

export const Value = styled.p``;

export const Obj = styled.div`
  width: 100%;
`;
