import { UserManagerSettings, WebStorageStateStore } from "oidc-client";
import { createUserManager } from "redux-oidc";

export const getUriFromAmbiente = () => {
  const ambiente = localStorage.getItem("ongo_ambiente");
  if (!ambiente) {
    return process.env.REACT_APP_AUTH_URL;
  } else {
    switch (ambiente) {
      case "homolog":
        return process.env.REACT_APP_AUTH_URL_HOMOLOG;
      case "stage":
        return process.env.REACT_APP_AUTH_URL_STAGE;
      default:
        return process.env.REACT_APP_AUTH_URL;
    }
  }
};

const userManagerConfig: UserManagerSettings = {
  authority: getUriFromAmbiente(),
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  prompt: "login",
  response_type: "code",
  redirect_uri:
    window.location.protocol + "//" + window.location.host + "/oauthredirect",
  post_logout_redirect_uri:
    window.location.protocol + "//" + window.location.host + "/logoutredirect",
  scope: process.env.REACT_APP_AUTH_SCOPES,
  automaticSilentRenew: true,
  silent_redirect_uri:
    window.location.protocol + "//" + window.location.host + "/oauthredirect",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  revokeAccessTokenOnSignout: true,
  monitorSession: false,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
