import { Select, MenuItem } from "@material-ui/core";
import { colorClasses } from "../../Utils/Color";
import { Search } from "../Icons/search";
import { TextInputState } from "../UI/Inputs/TextInput";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 7vh;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  top: 0;
  width: calc(100% - 64px);
  z-index: 0;
`;

export const CenterSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RightSide = styled.div`
  position: absolute;
  right: 5%;
`;

export const InputGroup = styled.div`
  position: relative;
`;

export const InputSearch = styled(TextInputState)`
  border-radius: 50px;
  background-color: #6844c9;
  color: #ffffff;
  border: none;
  padding: 7px 10px 7px 30px;
  outline: none;
  &::placeholder {
    color: #ffffff;
  }
`;

export const SearchIcon = styled(Search)`
  position: absolute;
  left: 5%;
  top: 60%;
  transform: translateY(-50%);
  z-index: 1;
  width: 20px;
`;

export const SelectMenu = styled(Select)`
  && {
    height: 5vh;
    width: auto;
    padding: 0 5px;
    color: #473799;
    & > svg {
      color: #473799;
    }
    & > fieldset {
      border: transparent;
    }
  }
`;

export const MenuOption = styled(MenuItem)`
  && {
    color: #473799;
    border: none;
  }
`;

export const Logo = styled.img`
  max-height: 2rem;
`;

export const MenuItemNav = styled.div`
  margin-left: 2rem;
  display: flex;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  a {
    align-self: center;
    text-decoration: none;
    color: ${colorClasses["primary"]};
    cursor: pointer;
  }

  &.active {
    border-bottom: 1px solid ${colorClasses["primary"]};
  }

  height: 100%;
`;
