import { ISearch } from "../Interfaces/Request/Base/ISearch";

class ApiBase {
  static createUrl(
    baseUrl: string,
    params?: { key: string; value: string; divider?: string }[],
    search?: ISearch
  ): string {
    let url = baseUrl;
    if (!params) {
      return this.addSearch(url, search);
    }

    if (search && search.divider === "/" && !search.after) {
      url = this.addSearch(url, search);
    }

    params.forEach((x, i) => {
      const isFirst = i === 0;
      if (!x.divider) {
        url += `${isFirst ? "?" : "&"}${x.key}=${encodeURIComponent(x.value)}`;
      } else {
        url += `${x.divider}${encodeURIComponent(x.value)}`;
      }
    });

    return search && search.divider === "/" && !search.after
      ? url
      : this.addSearch(url, search);
  }

  static addSearch(url: string, search?: ISearch): string {
    if (!search) {
      return url;
    }

    return search.divider === "param"
      ? this.addParamDivider(url, search)
      : this.addSlashDivider(url, search);
  }

  static addParamDivider(url: string, search: ISearch) {
    const isFirstParam = url.indexOf("?") === -1;

    url += `${isFirstParam ? "?" : "&"}pageSize=${search.pageSize || 10}`;
    url += `&pageIndex=${search.pageIndex || 0}`;
    url += (search.pesquisa && `&pesquisa=${search.pesquisa}`) || "";
    return url;
  }

  static addSlashDivider(url: string, search: ISearch) {
    url += `/${search.pageSize || 10}`;
    url += `/${search.pageIndex || 0}`;
    url += (search.pesquisa && `/${search.pesquisa}`) || "";
    return url;
  }
}

export default ApiBase;
