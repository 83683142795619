import { createGlobalStyle } from "styled-components";
import { colorClasses } from "../Utils/Color";
import FordAntemma from "../Utils/Fonts/FordAntennaComp-MediumItalic.ttf";

const GlobalStyles = createGlobalStyle`

body {
  background-color: ${colorClasses["bgDefault"]};;
}

@font-face {
  font-family: 'Ford Antenna Medium Italic';
  src: url(${FordAntemma}) format('otf'), url(${FordAntemma});
}
`;

export default GlobalStyles;
