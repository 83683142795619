import { Button } from "@material-ui/core";
import styled from "@emotion/styled";
import { colorClasses, ColorName } from "../../../../Utils/Color";

export const CustomButton = styled(Button)<{ customColor?: ColorName }>`
  display: flex;
  flex-direction: row;
  align-content: center;
  max-height: 2.5rem;
  background-color: ${({ customColor }) =>
    colorClasses[customColor || "primary"]};

  &:hover {
    background-color: ${({ customColor }) =>
      colorClasses[customColor || "primary"]};
  }

  color: #fff;

  svg.MuiSvgIcon-root {
    padding-right: 10px;
    height: 100%;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
`;
