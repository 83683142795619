import { AllRelatoriosActionKeys, RelatoriosActionUnion } from "./action";
import { IAllRelatoriosState } from "./IAllRelatoriosState";

const initialState: IAllRelatoriosState = {
  result: {
    data: {
      data: [],
      pageIndex: 0,
      pageSize: 50,
      success: true,
      totalResult: 0,
    },
    success: true,
  },
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: RelatoriosActionUnion
): IAllRelatoriosState => {
  switch (action.type) {
    case AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_SUCCEEDED:
      return {
        ...state,
        result: {
          ...action.payload,
          data: action.payload.data || []
        },
        isLoading: false,
      };
    case AllRelatoriosActionKeys.FETCH_ALL_RELATORIOS_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
