import { FC, useEffect } from "react";
import { Logo, Box, Container } from "./styles";
import logoPng from "./../../Static/Images/Logo.png";

const LogoutCallBack: FC = () => {
  return (
    <Container>
      <Box>
        <Logo src={logoPng} />

        <p>Logout efetuado com sucesso.</p>
      </Box>
    </Container>
  );
};

export default LogoutCallBack;
