import {
  ProcessarRelatorioActionKeys,
  ProcessarRelatorioActionUnion,
} from "./action";
import { IProcessarRelatorioState } from "./IProcessarRelatorioState";

const initialState: IProcessarRelatorioState = {
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: ProcessarRelatorioActionUnion
): IProcessarRelatorioState => {
  switch (action.type) {
    case ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case ProcessarRelatorioActionKeys.FETCH_PROCESSAR_RELATORIO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
