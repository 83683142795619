import { Action, ActionsUnion, createAction } from "../../actionHelpers";
export enum PeriodoActionKeys {
  FETCH_PERSISTENCIA_PERIODO_SUCCEEDED = "[PERSISTENCIA_PERIODO] FETCH_PERSISTENCIA_PERIODO_SUCCEEDED",
  FETCH_PERSISTENCIA_PERIODO_FAILED = "[PERSISTENCIA_PERIODO] FETCH_PERSISTENCIA_PERIODO_FAILED",
}
export const PeriodoActions = {
  fetchPeriodoSucceeded: (periodo: number): fetchPeriodoSuccededAction =>
    createAction(
      PeriodoActionKeys.FETCH_PERSISTENCIA_PERIODO_SUCCEEDED,
      periodo
    ),
  fetchPeriodoFailed: (): fetchPeriodoFailedAction =>
    createAction(PeriodoActionKeys.FETCH_PERSISTENCIA_PERIODO_FAILED),
};
export type PeriodoActionUnion = ActionsUnion<typeof PeriodoActions>;
export type fetchPeriodoSuccededAction = Action<
  PeriodoActionKeys.FETCH_PERSISTENCIA_PERIODO_SUCCEEDED,
  number
>;
export type fetchPeriodoFailedAction =
  Action<PeriodoActionKeys.FETCH_PERSISTENCIA_PERIODO_FAILED>;
