import { FC } from "react";
import { Grid } from "@material-ui/core";
import moment from "moment";
import * as Styled from "./styled";
import { StatusRelatorio } from "../../../Data/Interfaces/Response/Relatorios/StatusRelatorio";
import { IRelatorioConsulta } from "../../../Data/Interfaces/Response/Relatorios/IRelatorioConsulta";
import { StatusConsulta } from "../../../Data/Interfaces/Response/Relatorios/StatusConsulta";
import { PrimaryButton } from "../Buttons";
import { Edit } from "@material-ui/icons";
import { typeViewRelatorioProps } from "../../../Pages/Relatorios/Detalhes";
import { useDispatch } from "react-redux";
import { AprovaManualRelatorioActions } from "../../../Redux/Relatorios/AprovaManualRelatorio/action";
import { FloatInputFormActions } from "../../../Redux/Form/FloatInput/action";
import RelatorioHelper from "../../../Utils/RelatorioHelper";

interface IDetailContainer {
  data: any;
  consulta: IRelatorioConsulta;
  typeView: typeViewRelatorioProps;
  statusRelatorio: StatusRelatorio;
  reprovarDocumento: (idConsulta: string) => void;
}

const DetailContainer: FC<IDetailContainer> = ({
  data,
  statusRelatorio,
  consulta,
  reprovarDocumento,
  typeView,
}) => {
  const dispatch = useDispatch();

  const renderItem = (key: string, value: any) => {
    return (
      <>
        <Styled.Wrapper>
          <Grid item xs={6}>
            <Styled.Label>
              {key
                .replace(/[^\w\s]/gi, "")
                .replace(/_/gi, "")
                .replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase())
                .replace(/([A-Z])/g, " $1")
                .trim()}
              :
            </Styled.Label>
          </Grid>
          <Grid item>
            <Styled.Value>{RelatorioHelper.parsePropValue(value)}</Styled.Value>
          </Grid>
          {(typeof value !== "object" || value === null) &&
            consulta.statusConsulta === StatusConsulta.REVISAR &&
            statusRelatorio === StatusRelatorio.REVISAR &&
            !Array.isArray(value) && (
              <PrimaryButton
                icon={<Edit fontSize="small" color="primary" />}
                customColor="transparent"
                onClick={() => {
                  dispatch(
                    FloatInputFormActions.fetchOpenFloatInputForm({
                      inputValue: value,
                      onSave(value) {
                        dispatch(
                          AprovaManualRelatorioActions.fetchEditDadosRelatorioValue(
                            {
                              idConsulta: consulta.id,
                              newValue: value,
                              propertyName: key,
                              typeView: typeView,
                            }
                          )
                        );
                      },
                    })
                  );
                }}
              />
            )}
        </Styled.Wrapper>
        {typeof value === "object" && !Array.isArray(value) && value && (
          <Grid item xs={12}>
            {Object.entries(value).map(([key, value]) =>
              renderItem(key, value)
            )}
          </Grid>
        )}
      </>
    );
  };
  return (
    <Styled.Main>
      <Styled.Header>
        <Styled.ButtonActions>
          {statusRelatorio === StatusRelatorio.REVISAR &&
            consulta.statusConsulta === StatusConsulta.REVISAR && (
              <PrimaryButton
                onClick={() => reprovarDocumento(consulta.id)}
                customColor="error"
              >
                Reprovar Consulta
              </PrimaryButton>
            )}
        </Styled.ButtonActions>
        <Styled.Tittle>Dados do OCR</Styled.Tittle>
        <Styled.ButtonActions>
          {statusRelatorio === StatusRelatorio.REVISAR &&
            consulta.statusConsulta === StatusConsulta.REVISAR && (
              <PrimaryButton
                onClick={() =>
                  dispatch(
                    AprovaManualRelatorioActions.fetchAprovaManualRelatorio()
                  )
                }
                customColor="info"
              >
                Revisar Dados e Aprovar
              </PrimaryButton>
            )}
        </Styled.ButtonActions>
      </Styled.Header>
      {consulta.motivo &&
        consulta.statusConsulta !== StatusConsulta.APROVADA && (
          <Styled.Body>
            <Grid
              container
              xs={12}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Styled.Value>{consulta.motivo}</Styled.Value>
            </Grid>
          </Styled.Body>
        )}
      <Styled.Body>
        {data.map((x: any) => {
          return Object.entries(x).map(([key, value]) =>
            renderItem(key, value)
          );
        })}
      </Styled.Body>
    </Styled.Main>
  );
};

export default DetailContainer;
