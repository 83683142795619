import { IResponseErrorAPI } from "../../../Data/Interfaces/Response/Base/IResponseErrorAPI";
import { IDadosRelatorio } from "../../../Data/Interfaces/Response/Relatorios/IDadosRelatorio";
import { typeViewRelatorioProps } from "../../../Pages/Relatorios/Detalhes";
import { createAction, Action, ActionsUnion } from "../../actionHelpers";

export enum AprovaManualRelatorioActionKeys {
  FETCH_APROVA_MANUAL_RELATORIO_STARTED = "[RELATORIOS] FETCH_APROVA_MANUAL_RELATORIO_STARTED",
  FETCH_APROVA_MANUAL_RELATORIO_SUCCEEDED = "[RELATORIOS] FETCH_APROVA_MANUAL_RELATORIO_SUCCEEDED",
  FETCH_EDIT_DADOS_RELATORIO = "[RELATORIOS] FETCH_EDIT_DADOS_RELATORIO",
  FETCH_APROVA_MANUAL_RELATORIO_FAILED = "[RELATORIOS] FETCH_APROVA_MANUAL_RELATORIO_FAILED",
}

interface EditDadosRelatorio {
  propertyName: string;
  newValue: any;
  idConsulta: string;
  typeView: typeViewRelatorioProps;
}

export const AprovaManualRelatorioActions = {
  fetchAprovaManualRelatorio: (): fetchAprovaManualRelatorioAction =>
    createAction(
      AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_STARTED
    ),
  fetchEditDadosRelatorioValue: (
    data: EditDadosRelatorio
  ): fetchEditDadosRelatorioValueAction =>
    createAction(
      AprovaManualRelatorioActionKeys.FETCH_EDIT_DADOS_RELATORIO,
      data
    ),
  fetchAprovaManualRelatorioSucceeded:
    (): fetchAprovaManualRelatorioSuccededAction =>
      createAction(
        AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_SUCCEEDED
      ),
  fetchAprovaManualRelatorioFailed: (
    error: IResponseErrorAPI
  ): fetchAprovaManualRelatorioFailedAction =>
    createAction(
      AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_FAILED,
      error
    ),
};

export type AprovaManualRelatorioActionUnion = ActionsUnion<
  typeof AprovaManualRelatorioActions
>;

export type fetchAprovaManualRelatorioAction =
  Action<AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_STARTED>;
export type fetchEditDadosRelatorioValueAction = Action<
  AprovaManualRelatorioActionKeys.FETCH_EDIT_DADOS_RELATORIO,
  EditDadosRelatorio
>;
export type fetchAprovaManualRelatorioSuccededAction =
  Action<AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_SUCCEEDED>;
export type fetchAprovaManualRelatorioFailedAction = Action<
  AprovaManualRelatorioActionKeys.FETCH_APROVA_MANUAL_RELATORIO_FAILED,
  IResponseErrorAPI
>;
