import { AxiosResponse } from "axios";
import { Http } from "../../../Utils/Http";
import { ISearch } from "../../Interfaces/Request/Base/ISearch";
import { INovoRelatorio } from "../../Interfaces/Request/Relatorio/INovoRelatorio";
import { IRespostaManualConsulta } from "../../Interfaces/Request/Relatorio/IRespostaManualConsulta";
import { IResponseAPI } from "../../Interfaces/Response/Base/IResponseAPI";
import { IResponseErrorAPI } from "../../Interfaces/Response/Base/IResponseErrorAPI";
import { IResponseListAPI } from "../../Interfaces/Response/Base/IResponseListAPI";
import { IAllRelatorios } from "../../Interfaces/Response/Relatorios/IAllRelatorios";
import {
  IDadosRelatorio,
  IFotosDadosRelatorio,
} from "../../Interfaces/Response/Relatorios/IDadosRelatorio";
import { IDashboardConfiguracaoRelatorio } from "../../Interfaces/Response/Relatorios/IDashboardConfiguracaoRelatorio";
import { INovoRelatorioResponse } from "../../Interfaces/Response/Relatorios/INovoRelatorioResponse";
import { StatusRelatorio } from "../../Interfaces/Response/Relatorios/StatusRelatorio";
import { IRelatorioAPI } from "../../Interfaces/Services/IRelatorioAPI";
import ApiBase from "../ApiBase";

class RelatorioAPI implements IRelatorioAPI {
  async fetchDetalhesRelatorio(
    id: string
  ): Promise<
    AxiosResponse<IResponseAPI<IDadosRelatorio> | IResponseErrorAPI, any>
  > {
    const axios = await Http.axios();
    const url = ApiBase.createUrl(
      `/api/Relatorio/dados/${id}?ignoreFotos=true`
    );
    return axios.get<IResponseAPI<IDadosRelatorio> | IResponseErrorAPI>(url);
  }
  async fetchProcessaRelatorio(
    idRelatorio: string
  ): Promise<AxiosResponse<IResponseAPI<any> | IResponseErrorAPI, any>> {
    const axios = await Http.axios();
    const url = ApiBase.createUrl(`/api/Relatorio/processa-relatorio/${idRelatorio}`);
    return axios.post<IResponseAPI<any> | IResponseErrorAPI>(url);
  }
  async fetchUpdateStatus(
    idRelatorio: string
  ): Promise<AxiosResponse<IResponseAPI<any> | IResponseErrorAPI, any>> {
    const axios = await Http.axios();
    const url = ApiBase.createUrl(`/api/Relatorio/update-status/${idRelatorio}`);
    return axios.post<IResponseAPI<any> | IResponseErrorAPI>(url);
  }
  async fetchRespostaManualConsulta(
    data: IRespostaManualConsulta
  ): Promise<AxiosResponse<IResponseAPI<any> | IResponseErrorAPI, any>> {
    const axios = await Http.axios();
    const url = ApiBase.createUrl(`/api/Relatorio/reprova-manual-consulta`);
    return axios.post<IResponseAPI<any> | IResponseErrorAPI>(url, data);
  }
  async fetchAprovaManualRelatorio(
    data: IDadosRelatorio
  ): Promise<AxiosResponse<IResponseAPI<any> | IResponseErrorAPI, any>> {
    const axios = await Http.axios();
    const url = ApiBase.createUrl(`/api/Relatorio/aprova-manual-relatorio`);
    return axios.post<IResponseAPI<any> | IResponseErrorAPI>(url, data);
  }
  async fetchFotosDetalhesRelatorio(
    id: string
  ): Promise<
    AxiosResponse<IResponseAPI<IFotosDadosRelatorio> | IResponseErrorAPI, any>
  > {
    const axios = await Http.axios();
    const url = ApiBase.createUrl(`/api/Relatorio/fotos/${id}`);
    return axios.get<IResponseAPI<IFotosDadosRelatorio> | IResponseErrorAPI>(
      url
    );
  }
  async fetchNovoRelatorio(
    data: INovoRelatorio
  ): Promise<
    AxiosResponse<IResponseAPI<INovoRelatorioResponse[]> | IResponseErrorAPI>
  > {
    const axios = await Http.axios();
    const url = ApiBase.createUrl("/api/Relatorio/novo");
    return axios.post<
      IResponseAPI<INovoRelatorioResponse[]> | IResponseErrorAPI
    >(url, data);
  }
  async fetchConfiguracoesRelatorios(
    lastDays: number = 7,
    pesquisa: string | undefined = undefined
  ): Promise<AxiosResponse<IResponseAPI<IDashboardConfiguracaoRelatorio[]>>> {
    const axios = await Http.axios();
    let url = ApiBase.createUrl(
      `/api/ConfiguracaoRelatorio/dashboard/${lastDays}`
    );
    if (pesquisa) {
      url += `?pesquisa=${pesquisa}`;
    }
    return axios.get<IResponseAPI<IDashboardConfiguracaoRelatorio[]>>(url);
  }
  async fetchAllRelatorios(
    data: ISearch,
    identificador?: string,
    statusRelatorio?: StatusRelatorio,
    periodo?: number
  ): Promise<
    AxiosResponse<IResponseListAPI<IAllRelatorios> | IResponseErrorAPI, any>
  > {
    const axios = await Http.axios();
    data.divider = "param";
    data.after = true;
    const url = ApiBase.createUrl(
      "/api/Relatorio",
      [
        {
          key: "identificador",
          value: identificador || "",
          divider: "/",
        },
        {
          key: "statusRelatorio",
          value: statusRelatorio?.toString() || "",
          divider: "/",
        },
        {
          key: "periodo",
          value: periodo?.toString() || "",
          divider: "/",
        },
      ],
      data
    );
    return axios.get<IResponseListAPI<IAllRelatorios> | IResponseErrorAPI>(url);
  }
}

export default new RelatorioAPI();
