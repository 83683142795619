import {
  RespostaManualConsultaActionKeys,
  RespostaManualConsultaActionUnion,
} from "./action";
import { IRespostaManualConsultaState } from "./IRespostaManualConsultaState";

const initialState: IRespostaManualConsultaState = {
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: RespostaManualConsultaActionUnion
): IRespostaManualConsultaState => {
  switch (action.type) {
    case RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case RespostaManualConsultaActionKeys.FETCH_RESPOSTA_MANUAL_CONSULTA_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
