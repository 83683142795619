export enum StatusRelatorio {
    PROCESSANDO = 0,
    CONCLUIDO = 1,
    REVISAR = 2,
    RECUSADO = 3
}

export const getNomeFromStatus = (status: StatusRelatorio): string => {
    switch (status) {
        case StatusRelatorio.CONCLUIDO:
            return 'Concluidos';
        case StatusRelatorio.REVISAR:
            return 'Aguardando revisão';
        case StatusRelatorio.RECUSADO:
            return 'Reprovados';
        default:
            return 'Processando';
    }
}