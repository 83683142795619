import { FC, useEffect } from "react";
import { ArrowLeftOutlined } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../Components/UI/Buttons";
import { RootState } from "../../Redux";
import { Container, Content, RefreshPage } from "./styles";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import MainSwitch from "./MainSwitch";
import OneSignal from "react-onesignal";
import { securityService } from "../../Utils/SecurityService";

const ShellHost: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { oidc } = useSelector((states: RootState) => {
    return {
      oidc: states.oidc,
    };
  });

  useEffect(() => {
    (async () => {
      if (oidc.user?.access_token) {
        await OneSignal.init({
          appId: process.env.REACT_APP_ONE_SIGNAL || "",
          allowLocalhostAsSecureOrigin: true,
          persistNotification: true,
          autoResubscribe: true,
          autoRegister: true,
        });

        const userOrg = securityService.getUserOrganizacao(
          oidc.user.access_token
        );

        const userId = securityService.getUserIdToken(oidc.user.access_token);

        await OneSignal.setExternalUserId(userId);
        await OneSignal.sendTag("Organizacao", userOrg);
      }
    })();
  }, [oidc]);

  return (
    <>
      {oidc.user?.access_token !== undefined && <Header />}
      {oidc.user?.access_token !== undefined && <Sidebar />}
      <Container>
        {oidc.user?.access_token !== undefined &&
          location.pathname !== "/relatorios" && (
            <RefreshPage>
              <PrimaryButton
                icon={<ArrowLeftOutlined fontSize="small" />}
                customColor={"primary"}
                disabled={oidc.isLoadingUser}
                onClick={() => {
                  navigate(-1);
                }}
              />
            </RefreshPage>
          )}
        <Content>
          <MainSwitch />
        </Content>
      </Container>
    </>
  );
};

export default ShellHost;
