import { call, put } from "redux-saga/effects";
import RelatoriosAPI from "./../../../Data/Services/Relatorios";
import { fetchNovoRelatorioAction, NovoRelatorioActions } from "./action";

export function* fetchNovoRelatorio(action: fetchNovoRelatorioAction) {
  try {
    const { data } = yield call(
      RelatoriosAPI.fetchNovoRelatorio,
      action.payload
    );

    yield put(NovoRelatorioActions.fetchNovoRelatorioSucceeded(data));
  } catch (e: any) {
    yield put(NovoRelatorioActions.fetchNovoRelatorioFailed(e.message));
  }
}
