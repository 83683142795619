import { FC, useEffect } from "react";
import { Logo, Box, Container } from "./styles";
import logoPng from "./../../Static/Images/Logo.png";
import userManager from "../../Utils/UserManager";

const Login: FC = () => {
  useEffect(() => {
    (async () => {
      if (localStorage.getItem("isSignout") !== "true") {
        await userManager.signinRedirect();
      } else {
        localStorage.removeItem("isSignout");
      }
    })();
  }, []);

  return (
    <Container>
      <Box>
        <Logo src={logoPng} />
      </Box>
    </Container>
  );
};

export default Login;
