import {
  AtualizarStatusRelatorioActionKeys,
  AtualizarStatusRelatorioActionUnion,
} from "./action";
import { IAtualizarStatusRelatorioState } from "./IAtualizarStatusRelatorioState";

const initialState: IAtualizarStatusRelatorioState = {
  error: {
    data: [],
    success: false,
  },
  isLoading: false,
};

const relatoriosReducer = (
  state = initialState,
  action: AtualizarStatusRelatorioActionUnion
): IAtualizarStatusRelatorioState => {
  switch (action.type) {
    case AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
      };
    case AtualizarStatusRelatorioActionKeys.FETCH_ATUALIZAR_STATUS_RELATORIO_FAILED:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default relatoriosReducer;
