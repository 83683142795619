import React, { FC, useState } from "react";
import _ from "lodash";
import { Label } from "../Label";
import { Container, Content, InputStyle } from "./styles";

export interface ITextInput {
  className?: string;
  name: string;
  placeHolder?: string;
  label: string;
  disabled?: boolean;
  iconString?: string;
  type?: "number" | "text" | "password";
}

export interface ITextInputState extends ITextInput {
  onChangeText?: (value: string | number | undefined) => void;
  onChange?: (event: React.ChangeEvent<any> | any) => void;
  onClick?: () => void;
  initialVal?: string | undefined;
  input?: any;
  hasIconDivider?: boolean;
}

export const TextInputState: FC<ITextInputState> = (props) => {
  const [inputValue, setInputValue] = useState<string | undefined>(
    props.initialVal
  );

  const [filterChangedSubject] = useState(() => {
    // Arrow function is used to init Singleton Subject. (in a scope of a current component)
    return _.debounce((text: string) => {
      const value = text === "" ? undefined : text;
      if (props.onChangeText) {
        props.onChangeText(value);
      }
    }, 300);
  });

  return (
    <Container onClick={() => props.onClick && props.onClick()}>
      {props.label && <Label>{props.label}</Label>}
      <Content>
        <InputStyle
          className={`${props.className}`}
          type={props.type || "text"}
          disabled={props.disabled}
          {...props.input}
          value={props.input ? props.input.value : inputValue}
          onChange={(e) => {
            if (props.input) {
              props.input.onChange(e);
            } else if (props.onChange) {
              props.onChange(e);
            }
            if (props.onChangeText) {
              filterChangedSubject(e.target.value);
            }
            setInputValue(e.target.value);
          }}
          placeholder={props.placeHolder}
        />
      </Content>
    </Container>
  );
};
