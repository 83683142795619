import { useLocation } from "react-router-dom";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function isValidQueryParam(val: string | undefined | number | null): string | undefined {
    if (val && val !== 'null' && val !== 'undefined' && val !== undefined && val !== null) {
        return val as string;
    }

    return undefined;
}

export { useQuery, isValidQueryParam };